import React from 'react';
import './BookingConfirmationView.less';
import NavigationBar from '../../../components/NavigationBar/NavigationBar';
import BrandButton from '../../../components/BrandButton/BrandButton';
import PointItem from '../../../components/PointItem/PointItem';
import ListItem from '../../../components/ListItem/ListItem';
import ExpandableInsuranceItem from '../../../components/ExpandableInsuranceItem/ExpandableInsuranceItem';
import { GA_TITLES } from '../../../../utils';
import {
  CircleData,
  isMBA,
  isPayinPayment,
  PreSignupBooking,
  UserDataOutput,
} from 'getaway-data-layer';
import ExpandableEstimatedCost from '../../../components/ExpandableEstimatedCost/ExpandableEstimatedCost';
import ExpandableItem from '../../../components/ExpandableItem/ExpandableItem';
import Hyperlink from 'react-native-hyperlink';

type props = {
  localize: any;
  onClose: () => void;
  address: string;
  carPlate: string;
  insurancesData: any;
  selectedInsurance: any;
  onInsuranceSelected: any;
  from: any;
  until: any;
  onSubmit: any;
  onAddressClicked: () => void;
  user: UserDataOutput;
  estimatedCost: any;
  onPressPriceInfo: () => void;
  circles: Array<CircleData>;
  selectedCircle: CircleData;
  onClickCircles: () => void;
  cancellationFee: string;
  mbaCancellationFee: string;
  cancellationDueDate: string;
  alternativeCancelDueDate: string;
  extras: any;
  isDriverInjurySelected: boolean;
  onPressExtraOption: () => void;
  preSignupBooking: PreSignupBooking;
  clearPreSignupBooking: () => void;
};

const BookingConfirmationView = ({
  localize,
  onClose,
  carPlate,
  address,
  insurancesData,
  selectedInsurance,
  onInsuranceSelected,
  from,
  until,
  onSubmit,
  onAddressClicked,
  user,
  estimatedCost,
  onPressPriceInfo,
  circles,
  selectedCircle,
  onClickCircles,
  cancellationFee,
  mbaCancellationFee,
  cancellationDueDate,
  alternativeCancelDueDate,
  extras,
  isDriverInjurySelected,
  onPressExtraOption,
  preSignupBooking,
  clearPreSignupBooking,
}: props) => {
  const payInDialog = (
    <div className="booking-confirm-warning">
      <div className="booking-confirm-warning-title">
        {localize('booking.confirm.payin.title')}
      </div>
      <div className="booking-confirm-warning-msg">
        {localize('booking.confirm.payin.text')}
      </div>
    </div>
  );
  return (
    <div className="screen-container">
      <NavigationBar
        title={localize('booking.confirmation.title')}
        description=""
        onClose={onClose}
      />
      <div className="screen-body booking-confirmation-content">
        <PointItem title={localize('station.booking.group')} />
        <div className="booking-confirm-section">
          <img
            alt="dashedPath"
            src="/images/dashedPath.png"
            className="booking-confirmation-dashed"
          />
          {isPayinPayment(user?.paymentMethods) && payInDialog}
          {circles?.length > 1 && selectedCircle?.name && (
            <ExpandableItem
              containerStyles={{}}
              title={localize('booking.consent.circle')}
              titleClassName="circles-item-title"
              value={
                selectedCircle?.name.length > 17
                  ? `${selectedCircle?.name.slice(0, 17)}...`
                  : selectedCircle?.name
              }
              onClickOver={onClickCircles}
            />
          )}
          <ExpandableEstimatedCost
            localize={localize}
            insurancesData={insurancesData}
            estimatedCost={estimatedCost}
            onPressPriceInfo={onPressPriceInfo}
          />
          <ExpandableInsuranceItem
            localize={localize}
            insurancesData={insurancesData}
            onInsuranceSelected={onInsuranceSelected}
            selectedInsurance={selectedInsurance}
          />
          {extras?.map((extra: { code: string }) => (
            <ListItem
              key={extra.code}
              title={localize(`rental.extra.${extra.code}.title`)}
              greyTextColor
              onClick={onPressExtraOption}
              checkboxChecked={isDriverInjurySelected}
              checkboxItem
            />
          ))}
          <ListItem
            title={localize('rental.fuelSettings.where')}
            greyTextColor
            value={address}
            brandValueColor
            rightIcon="navigationArrow"
            rightIconSize={14}
            showSeparator
            onClick={onAddressClicked}
          />
          <div className="booking-confirmation-insurance-desc">
            {localize('bookings.where.desc')}
          </div>
        </div>
        <PointItem title={localize('booking.headline.reservation')} />
        <div className="booking-confirm-section">
          <div className="booking-confirm-section-line" />
          {(cancellationFee || (isMBA() && mbaCancellationFee)) && (
            <ExpandableItem
              title={localize('booking.fee.title')}
              containerStyles={{}}
              titleClassName="circles-item-title"
              value={cancellationDueDate}
              initiallyOpened
              showSeparator
            >
              <div style={{ paddingBottom: 16 }}>
                <Hyperlink
                  linkStyle={{ fontWeight: 700 }}
                  linkText={(url) => {
                    if (url === localize('booking.fee.fake.url')) {
                      return localize(
                        isMBA() && mbaCancellationFee
                          ? mbaCancellationFee
                          : cancellationFee
                      );
                    }

                    if (url === localize('booking.date.fake.url')) {
                      return alternativeCancelDueDate;
                    }

                    return url;
                  }}
                  onPress={() => {}}
                >
                  {localize(
                    'booking.cancel.fee.footnote',
                    localize('booking.fee.fake.url'),
                    localize('booking.date.fake.url')
                  )}
                </Hyperlink>
              </div>
            </ExpandableItem>
          )}
          <ListItem
            title={localize('booking.confirm.profile')}
            greyTextColor
            carPlate={carPlate}
            showSeparator
            onClick={() => {}}
          />
          {!!from && (
            <ListItem
              title={localize('booking.confirm.from')}
              greyTextColor
              value={from}
              valueStyles={{ color: 'black' }}
              showSeparator
              onClick={() => {}}
            />
          )}
          {!!until && (
            <ListItem
              title={localize('booking.confirm.to')}
              greyTextColor
              value={until}
              valueStyles={{ color: 'black' }}
              showSeparator
              onClick={() => {}}
            />
          )}
        </div>
        {preSignupBooking && (
          <div className="booking-cancel" onClick={clearPreSignupBooking}>
            {localize('booking.pre.signup.clear')}
          </div>
        )}
        <div className="booking-confirmation-vertical-space" />
        <BrandButton
          id={GA_TITLES.BOOKING.CONFIRM}
          title={localize('booking.confirmation.button')}
          onClick={onSubmit}
        />
      </div>
    </div>
  );
};

export default BookingConfirmationView;
