import React from 'react';
import './ServicesTabView.less';
import {
  isBND,
  isCTF,
  isFRM,
  isLocalizeAdded,
  isTLA,
  isSPS,
  LocalizePropType,
} from 'getaway-data-layer';
import ServiceItem from '../../../../components/ServiceItem/ServiceItem';
import { openLink } from '../../../../../utils';

type Props = {
  localize: LocalizePropType;
  merchantSystemData: any;
  isORG: boolean;
  tariff: string;
  email: string;
  paymentAccount?: any;
  sepa?: any;
};

const mapMerchantToService = (merchantName) => {
  const mapping = {
    BND: { HAL: 'bnd', eBUS: 'cityflitzer' },
    FRM: { eBUS: 'FRM' },
    CTF: { eBUS: 'ctf', TIL: 'TIL' },
    TLA: { eBUS: 'ctf', TIL: 'TIL' },
    SPS: { HAL: 'sps' },
  };

  if (isBND() && mapping.BND[merchantName]) return mapping.BND[merchantName];
  if (isFRM() && mapping.FRM[merchantName]) return mapping.FRM[merchantName];
  if (isCTF() && mapping.CTF[merchantName]) return mapping.CTF[merchantName];
  if (isTLA() && mapping.TLA[merchantName]) return mapping.TLA[merchantName];
  if (isSPS() && mapping.SPS[merchantName]) return mapping.SPS[merchantName];
};

const ServicesTabView = ({
  localize,
  merchantSystemData,
  isORG,
  tariff,
  email,
  paymentAccount,
  sepa,
}: Props) => {
  const merchantSystems = isCTF()
    ? [...merchantSystemData, { merchantSystem: 'TIL', status: 'PENDING' }]
    : isTLA()
    ? [
        { merchantSystem: 'TIL', status: 'PENDING' },
        ...(!isORG ? merchantSystemData : []),
      ]
    : merchantSystemData;

  return (
    <div>
      {merchantSystems?.map((merchant) => {
        const service = mapMerchantToService(merchant?.merchantSystem);
        return (
          service && (
            <ServiceItem
              key={merchant?.merchantName}
              localize={localize}
              title={localize(`service.${service}.title`)}
              logo={`/images/${service}-logo.png`}
              background={`/images/${service}-bg.png`}
              description={localize(`service.${service}.desc`)}
              provider={localize(`service.${service}.provider`)}
              customerNr={merchant?.merchantReference || './.'}
              PIN={merchant?.status === 'DONE' ? merchant?.pin : './.'}
              onClickLogin={openLink(localize(`service.${service}.login.link`))}
              onClickImprint={openLink(
                localize(`service.${service}.imprint.link`)
              )}
              onClickDataProtection={openLink(
                localize(`service.${service}.data.link`)
              )}
              onClickAGB={openLink(localize(`service.${service}.agb.link`))}
              verified={merchant?.status === 'DONE'}
              timestamp={merchant?.syncedAt}
              tariff={tariff || './.'}
              email={email}
              sepa={sepa}
              paymentAccount={paymentAccount}
              tariffPartnerLink={
                isLocalizeAdded(
                  localize,
                  isORG
                    ? `service.tariff.partner.org.link`
                    : `service.tariff.partner.org`
                )
                  ? localize(
                      isORG
                        ? `service.tariff.partner.org.link`
                        : `service.tariff.partner.org`
                    )
                  : undefined
              }
              note={
                isLocalizeAdded(
                  localize,
                  isORG
                    ? `service.${service}.org.note`
                    : `service.${service}.note`
                )
                  ? localize(
                      isORG
                        ? `service.${service}.org.note`
                        : `service.${service}.note`
                    )
                  : undefined
              }
            />
          )
        );
      })}
    </div>
  );
};

export default ServicesTabView;
