export const handleRedirect = () => {
  window.open('/onboarding/redirectAction');
};

export const handleChallenge = () => {
  window.open('/onboarding/challengeAction');
};

export const handleIdentifyShopper = () => {
  window.open('/onboarding/identifyShopperAction');
};

export const handle3dsData = (paymentData: any) => {
  const { threeDS1, identifyShopperData, challengeShopperData } = paymentData;

  if (threeDS1) {
    handleRedirect();
  } else if (identifyShopperData) {
    handleIdentifyShopper();
  } else if (challengeShopperData) {
    handleChallenge();
  }
};

export const parsePaymentMethods = (paymentMethods: any) => ({
  paymentMethods: paymentMethods.paymentMethods
    .map((payment: any) =>
      payment?.type === 'paypal'
        ? {
            ...payment,
            configuration: {
              merchantId: payment.configuration.entry.find(
                (entry: any) => entry.key === 'merchantId'
              ).value,
              intent: payment.configuration.entry.find(
                (entry: any) => entry.key === 'intent'
              ).value,
            },
          }
        : null
    )
    .filter((n: any) => n),
});

export const getAdyenChcekoutConfigs = (
  locale: string,
  country: string,
  clientKey: string,
  paymentMethods: any,
  onSubmitDropinPayment: any,
  onSubmitDropinPaymentDetails: any
) => ({
  paymentMethodsResponse: paymentMethods, // The `/paymentMethods` response from the server.
  clientKey: clientKey, // Web Drop-in versions before 3.10.1 use originKey instead of clientKey.
  locale: locale,
  environment: process.env.REACT_APP_STAGE === 'production' ? 'live' : 'test',
  amount: {
    currency: 'EUR',
    value: 0,
  },
  countryCode: country,
  analytics: {
    enabled: false, // Set to false to not send analytics data to Adyen.
  },
  onSubmit: onSubmitDropinPayment,
  onAdditionalDetails: onSubmitDropinPaymentDetails,
  paymentMethodsConfiguration: {
    card: {
      // Example optional configuration for Cards
      hasHolderName: true,
      holderNameRequired: true,
      enableStoreDetails: true,
      hideCVC: false, // Change this to true to hide the CVC field for stored cards
      name: 'Credit or debit card',
      // onSubmit: () => {}, // onSubmit configuration for card payments. Overrides the global configuration.
    },
    paypal: {
      amount: {
        currency: 'EUR',
        value: 0,
      },
      countryCode: 'DE',
      style: {
        // Optional configuration for PayPal payment buttons.
        // layout: 'vertical',
        // color: 'blue',
      },
      cspNonce: 'MY_CSP_NONCE',
      onShippingChange: function (data: any, actions: any) {
        // Listen to shipping changes.
      },
      onInit: (data: any, actions: any) => {
        // onInit is called when the button first renders.
        // Call actions.enable() to enable the button.
        actions.enable();
        // Or actions.disable() to disable it.
      },
      onClick: () => {
        // onClick is called when the button is clicked.
      },
      blockPayPalCreditButton: true,
      blockPayPalPayLaterButton: true,
    },
  },
});
