import React from 'react';
import {
  withTextLocalizer,
  LocalizePropType,
  selectUser,
  maskSepa,
} from 'getaway-data-layer';
import { useSelector } from 'react-redux';
import ServicesTabView from './ServicesTabView';
import BrandIcon from '../../../../components/BrandIcon/BrandIcon';

type Props = {
  localize: LocalizePropType;
};

export const PROFILE_TABS = {
  ACCOUNT: 'ACCOUNT',
  SERVICES: 'SERVICES',
};

const ServicesTabContainer = ({ localize }: Props) => {
  const user = useSelector(selectUser) as any;
  const verifiedPayment = user.paymentMethods.find(
    (payment: any) => payment.status === 'verified' && payment?.sepa
  );
  return (
    <ServicesTabView
      localize={localize}
      isORG={user?.userType === 'org'}
      merchantSystemData={user?.merchantSystemData}
      tariff={user?.tariff?.name}
      email={user.email}
      sepa={verifiedPayment?.sepa?.iban}
      paymentAccount={
        verifiedPayment ? (
          user?.userType === 'org' ? (
            `${user.companyName.trim()} ${
              user?.legalType || user?.otherLegalType
            }`
          ) : (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <BrandIcon
                style={{
                  width: 33,
                  height: 25,
                  marginRight: 10,
                }}
                name="paymentSepa"
              />
              <div>{maskSepa(verifiedPayment.sepa.iban)}</div>
            </div>
          )
        ) : undefined
      }
    />
  );
};

export default withTextLocalizer(ServicesTabContainer);
