import React from 'react';
import Box from '@mui/material/Box';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { FormikValues } from 'formik';
import './LoginView.less';
import NavigationBar from '../../components/NavigationBar/NavigationBar';
import BrandButton from '../../components/BrandButton/BrandButton';
import { LocalizePropType, selectIsKeyboardOpened } from 'getaway-data-layer';
import { GA_TITLES, isMobileBrowser } from '../../../utils';
import { useSelector } from 'react-redux';

type props = {
  localize: LocalizePropType;
  formik: FormikValues;
  showPassword: boolean;
  onClose: () => void;
  onPressForgotPassword: () => void;
  handleClickShowPassword: () => void;
  onPressSignup: () => void;
  handleMouseDownPassword: (event: React.MouseEvent<HTMLButtonElement>) => void;
  showSignupLink: boolean;
};

const LoginView = ({
  localize,
  formik,
  showPassword,
  onClose,
  onPressForgotPassword,
  handleClickShowPassword,
  handleMouseDownPassword,
  onPressSignup,
  showSignupLink,
}: props) => {
  const isKeyboardOpened = useSelector(selectIsKeyboardOpened) as any;

  return (
    <div className="screen-container">
      <NavigationBar
        title={localize('login.title')}
        description=""
        showHelp
        onClose={onClose}
        showLogo
      />
      <div className="screen-body">
        <Box component="form" noValidate onSubmit={formik.handleSubmit}>
          <TextField
            required
            fullWidth
            id="email"
            className="form-input"
            label={localize('login.email.placeholder')}
            helperText={(formik.touched.email && formik.errors.email) || ''}
            error={formik.touched.email && Boolean(formik.errors.email)}
            variant="standard"
            type="email"
            autoComplete="email"
            {...formik.getFieldProps('email')}
            style={{ marginBottom: 16 }}
          />
          <TextField
            required
            fullWidth
            id="password"
            className="form-input"
            type={showPassword ? 'text' : 'password'}
            label={localize('login.password.placeholder')}
            helperText={
              (formik.touched.password && formik.errors.password) || ''
            }
            error={formik.touched.password && Boolean(formik.errors.password)}
            autoComplete="current-password"
            variant="standard"
            {...formik.getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <div
            onClick={onPressForgotPassword}
            className="login-view-forget-container"
          >
            <div className="login-view-forget-text">
              {localize('login.forgot.title')}
            </div>
          </div>
          {showSignupLink && (
            <div
              onClick={onPressSignup}
              className="login-footnote-container"
              style={
                isKeyboardOpened && isMobileBrowser()
                  ? { position: 'unset', width: '100%', marginTop: 30 }
                  : {}
              }
            >
              <div className="login-footnote-text">
                {localize('login.footnote.signup.text')}
              </div>
              <div className="login-footnote-action">
                {localize('login.footnote.signup.action')}
              </div>
            </div>
          )}
          <BrandButton
            id={GA_TITLES.AUTH.LOGIN}
            title={localize('login.button.title')}
            formik={formik}
          />
        </Box>
      </div>
    </div>
  );
};

export default LoginView;
