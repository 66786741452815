import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  LocalizePropType,
  isKYCApp,
  selectUser,
  setShowHomeWT,
  withTextLocalizer,
} from 'getaway-data-layer';
import HomeView from './HomeView';
import { showAboutDialog } from '../../../utils/dialogsHelpers';
import config from '../../../env.config';
import packageInfo from '../../../../package.json';
import { navigateTo } from '../../../utils';
import { useHistory } from 'react-router-dom';

type Props = {
  localize: LocalizePropType;
};
const HomeContainer = ({ localize }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const sliderRef = useRef();
  const user = useSelector(selectUser);
  const [sliderIndex, setSliderIndex] = useState(0);

  const onClickNext = () => {
    dispatch(setShowHomeWT(false));
    if (isKYCApp()) {
      navigateTo(history, '/signup');
    }
  };

  const onClickLogin = () => {
    dispatch(setShowHomeWT(false));
    navigateTo(history, '/login');
  };

  const afterSlide = (index: number) => {
    setSliderIndex(index);
    if (index === 2) {
      dispatch(setShowHomeWT(false));
    }
  };

  const onClickVersionHeader = () => {
    showAboutDialog(
      config.brand.xBrandId!!,
      localize,
      `© GETAWAY・v${packageInfo.version}`
    );
  };

  return (
    <HomeView
      localize={localize}
      user={user!}
      sliderIndex={sliderIndex}
      sliderRef={sliderRef}
      onClickNext={onClickNext}
      onClickLogin={onClickLogin}
      afterSlide={afterSlide}
      onClickVersionHeader={onClickVersionHeader}
    />
  );
};

export default withTextLocalizer(HomeContainer);
