import { LocalizePropType } from '../enhancers';

export const SUPPORTED_END_RENTAL_VIOLATIONS = [
  'insideArea',
  'fuelLevel',
  'extremelyLowFuelLevel',
  'bitLessFuelOnReturn',
];

export const getEndRentalViolationTitle = (
  violation: string,
  finishRentalViolations: Record<string, any>,
  pricingData: any,
  localize: LocalizePropType
) => {
  const violations = Object.keys(finishRentalViolations);
  const lowFuelFee = pricingData?.items?.find(
    (item: { code: string }) => item?.code === 'F3009'
  )?.price;
  switch (violation) {
    case 'insideArea':
      return violations.includes('finishLocationAllowed')
        ? finishRentalViolations.finishLocationAllowed
        : finishRentalViolations.insideArea;
    case 'fuelLevel':
      return lowFuelFee
        ? localize('handover.lowfuel.fee', lowFuelFee)
        : finishRentalViolations[violation];
    case 'extremelyLowFuelLevel':
    case 'bitLessFuelOnReturn':
      return finishRentalViolations[violation];
    default:
      return '';
  }
};
