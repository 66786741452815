import React from 'react';
import './BookingConsentView.less';
import NavigationBar from '../../../components/NavigationBar/NavigationBar';
import BrandButton from '../../../components/BrandButton/BrandButton';
import PointItem from '../../../components/PointItem/PointItem';
import ListItem from '../../../components/ListItem/ListItem';
import ExpandableInsuranceItem from '../../../components/ExpandableInsuranceItem/ExpandableInsuranceItem';
import { GA_TITLES } from '../../../../utils';
import { CircleData } from 'getaway-data-layer';
import ExpandableItem from '../../../components/ExpandableItem/ExpandableItem';

type props = {
  localize: any;
  onClose: () => void;
  address: string;
  carPlate: string;
  insurancesData: any;
  selectedInsurance: any;
  onInsuranceSelected: any;
  from: any;
  until: any;
  onSubmit: any;
  car: any;
  onAddressClicked: () => void;
  circles: Array<CircleData> | undefined;
  selectedCircle: CircleData;
  onClickCircles: () => void;
  onCancel: () => void;
  extras: any;
  isDriverInjurySelected: boolean;
  onPressExtraOption: () => void;
};

const BookingConsentView = ({
  localize,
  onClose,
  carPlate,
  address,
  insurancesData,
  selectedInsurance,
  onInsuranceSelected,
  car,
  circles,
  selectedCircle,
  onClickCircles,
  onCancel,
  from,
  until,
  onSubmit,
  onAddressClicked,
  extras,
  isDriverInjurySelected,
  onPressExtraOption,
}: props) => {
  return (
    <div className="screen-container">
      <NavigationBar
        title={localize('booking.confirmation.title')}
        description=""
        onClose={onClose}
      />
      <div className="screen-body booking-confirmation-content">
        <PointItem title={localize('station.booking.group')} />
        <div className="booking-consent-section">
          <img
            alt="dashedPath"
            src="/images/dashedPath.png"
            className="booking-confirmation-dashed"
          />
          {circles && circles?.length > 1 && selectedCircle?.name && (
            <ExpandableItem
              containerStyles={{}}
              title={localize('booking.consent.circle')}
              value={
                selectedCircle?.name.length > 17
                  ? `${selectedCircle?.name.slice(0, 17)}...`
                  : selectedCircle?.name
              }
              onClickOver={onClickCircles}
            />
          )}
          <ExpandableInsuranceItem
            localize={localize}
            insurancesData={insurancesData}
            onInsuranceSelected={onInsuranceSelected}
            selectedInsurance={selectedInsurance}
            initiallyOpened
          />
          {extras?.map((extra: { code: string }) => (
            <ListItem
              key={extra.code}
              title={localize(`rental.extra.${extra.code}.title`)}
              greyTextColor
              onClick={onPressExtraOption}
              checkboxChecked={isDriverInjurySelected}
              checkboxItem
            />
          ))}
          <ListItem
            title={localize('rental.fuelSettings.where')}
            greyTextColor
            value={address}
            brandValueColor
            rightIcon="navigationArrow"
            rightIconSize={14}
            showSeparator
            onClick={onAddressClicked}
          />
          <div className="booking-consent-insurance-desc">
            {localize('bookings.where.desc')}
          </div>
        </div>
        <PointItem title={localize('booking.headline.reservation')} />
        <div className="booking-consent-section">
          <div className="booking-consent-section-line" />
          <ListItem
            title={localize('booking.confirm.profile')}
            carPlate={carPlate}
            showSeparator={false}
            onClick={() => {}}
          />
          <div className="reserved-card-separator" />
          {!!from && (
            <>
              <ListItem
                title={localize('booking.confirm.from')}
                value={from}
                showSeparator={false}
                onClick={() => {}}
              />
              <div className="reserved-card-separator" />
            </>
          )}
          {!!until && (
            <>
              <ListItem
                title={localize('booking.confirm.to')}
                value={until}
                showSeparator={false}
                onClick={() => {}}
              />
              <div className="reserved-card-separator" />
            </>
          )}
        </div>
        <div className="booking-confirmation-vertical-space" />
        <div className="booking-consent-bottom">
          <img
            className="booking-consent-bottom-gradiant"
            src="/images/gradientBackground50.png"
            alt="gradiant"
          />
          <BrandButton
            id={GA_TITLES.BOOKING.CONSENT}
            containerStyles={{
              position: 'unset',
              width: '100%',
              marginBottom: '20px',
            }}
            title={localize('booking.consent.button')}
            onClick={onSubmit}
          />
          <div className="booking-consent-cancelContainer" onClick={onCancel}>
            <img
              alt="redClose"
              src="/images/redClose.png"
              className="booking-consent-closeIcon"
            />
            <div className="booking-consent-close">
              {localize('booking.consent.cancel')}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingConsentView;
