import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  withTextLocalizer,
  initJumioForWeb,
  selectJumioRedirectData,
  LocalizePropType,
  selectUser,
  isKYCApp,
  setNotification,
  APP,
  selectShowExternalBrowserScreen,
} from 'getaway-data-layer';
import { isDashboardScreen, navigateTo, showAlert } from '../../../../../utils';
import DLOnboardingView from './DLOnboardingView';
import ExternalBrowser from '../../../../components/ExternalBrowser/ExternalBrowser';

type Props = {
  localize: LocalizePropType;
};

const DLOnboardingContainer = ({ localize }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const jumioRedirectData: any = useSelector(selectJumioRedirectData);
  const user = useSelector(selectUser);
  const showExternalBrowserScreen = useSelector(
    selectShowExternalBrowserScreen
  );
  const scanEnabled = user?.remainingLicenseSubmissionAttempts > 0;
  const [isPlasticCard, setIsPlasticCard] = useState(true);

  const navigateToJumioScan = (data) => {
    window.location.href = data?.redirectUrl;
  };

  const onPressStart = () => {
    const redirectUrl = `${window?.location?.origin}${
      isDashboardScreen()
        ? '/settings'
        : isKYCApp()
        ? '/onboarding/optimizedSepa'
        : '/map'
    }`;
    if (scanEnabled) {
      dispatch(
        initJumioForWeb(
          localize,
          history,
          'DRIVING_LICENSE',
          jumioRedirectData?.transactionReference,
          user?.id,
          isDashboardScreen(),
          isKYCApp(),
          isKYCApp(),
          (data) => {
            navigateToJumioScan(data);
          },
          redirectUrl,
          redirectUrl
        )
      );
    }
  };

  const onClickDisabled = () => {
    dispatch(
      setNotification({
        message: 'user.scan.attempts.out',
        type: APP.NOTIFICATION_TYPE.ERROR,
      })
    );
  };

  const onClose = () => {
    if (isDashboardScreen()) {
      navigateTo(history, '/settings');
    } else {
      showAlert(
        '',
        localize('cancel.FirstSteps.dialog.message'),
        localize('cancel.FirstSteps.dialog.yes'),
        () => navigateTo(history, isKYCApp() ? '/settings' : '/map'),
        localize('cancel.FirstSteps.dialog.no'),
        () => {},
        true
      );
    }
  };

  return (
    <>
      {showExternalBrowserScreen ? (
        <ExternalBrowser localize={localize} onClose={onClose} />
      ) : (
        <DLOnboardingView
          isPlasticCard={isPlasticCard}
          setIsPlasticCard={setIsPlasticCard}
          localize={localize}
          scanEnabled={scanEnabled}
          onClose={onClose}
          onPressStart={onPressStart}
          onClickDisabled={onClickDisabled}
        />
      )}
    </>
  );
};

export default withTextLocalizer(DLOnboardingContainer);
