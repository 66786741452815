export const isDashboardScreen = () => {
  return (
    document.location.href.includes('/dashboard') ||
    document.location.href.includes('/settings')
  );
};

export const isFirstStepsScreen = () => {
  return document.location.href.includes('/onboarding');
};

export const isIncompleteProfile = (user: any) => {
  const hasPayment =
    user?.paymentMethods?.length &&
    user?.paymentMethods.find((payment: any) => payment.status === 'verified');
  const isVerifiedIdDocument = isIDVerificationRequired(user)
    ? getIDVerificationStatus(user) === 'completed' &&
      user?.verified?.identityDocument
    : true;
  const isVerifiedEmail = isEmailVerificationRequired(user)
    ? getEmailVerificationStatus(user) === 'completed'
    : true;
  const tariffSelected = isTariffEnabled(user)
    ? getTariffStatus(user) === 'completed'
    : true;

  return !(
    user &&
    user.verified.phoneNumber &&
    user.verified.address &&
    user.verified.drivingLicense &&
    hasPayment &&
    isVerifiedIdDocument &&
    isVerifiedEmail &&
    tariffSelected
  );
};

export const getPhoneStatus = (user: any) => {
  const firstStepsChallenge = user?.challenges?.find(
    (challenge: any) => challenge.type === 'firstSteps'
  );

  const tasks = firstStepsChallenge?.tasks || [];

  const phoneStatus = tasks?.find(
    (task: any) => task?.screen === '/user/verify-phone-number'
  )?.status;
  return phoneStatus !== 'completed'
    ? phoneStatus
    : user?.verified.phoneNumber
    ? 'completed'
    : 'open';
};

export const isAddressFull = (address: any): boolean =>
  address &&
  address.streetName &&
  address.streetName.length &&
  address.streetNumber &&
  address.streetNumber.length &&
  address.city &&
  address.city.length &&
  address.postalCode &&
  address.postalCode.length;

export const getAddressStatus = (user: any) => {
  const firstStepsChallenge = user?.challenges?.find(
    (challenge: any) => challenge.type === 'firstSteps'
  );

  const tasks = firstStepsChallenge?.tasks || [];
  const addressTaskStatus = tasks?.find(
    (task: any) => task?.screen === '/user/enter-address'
  )?.status;
  return isAddressFull(user?.address) ? addressTaskStatus : 'open';
};

export const getLicenseStatus = (user: any) => {
  const firstStepsChallenge = user?.challenges?.find(
    (challenge: any) => challenge.type === 'firstSteps'
  );

  const tasks = firstStepsChallenge?.tasks || [];

  const licenseStatus = tasks?.find(
    (task: any) => task?.screen === '/user/verify-drivers-license'
  )?.status;
  return licenseStatus !== 'completed'
    ? licenseStatus
    : user?.verified.drivingLicense
    ? 'completed'
    : 'deleted';
};

export const getIDStatus = (user: any) => {
  const firstStepsChallenge = user?.challenges?.find(
    (challenge: any) => challenge.type === 'firstSteps'
  );

  const tasks = firstStepsChallenge?.tasks || [];

  const idStatus = tasks?.find(
    (task: any) => task?.screen === '/user/verify-id-document'
  )?.status;
  return idStatus !== 'completed'
    ? idStatus
    : user?.verified.identityDocument
    ? 'completed'
    : 'deleted';
};

const formatLicenseEndDate = (licenceEndDate: any) => {
  const date = new Date(licenceEndDate);
  return `${date.getUTCMonth() < 9 ? '0' : ''}${
    date.getUTCMonth() + 1
  }/${date.getFullYear()}`;
};

export const formatLicenseStatus = (
  license: any,
  licenseStatus: string,
  localize: any
): string => {
  if (licenseStatus === 'completed') {
    if (license && license.interval && license.interval.to) {
      return `${localize(
        'driver.license.status.verified'
      )} ${formatLicenseEndDate(license.interval.to)}`;
    }
    return localize('driver.license.status.verified');
  }
  if (licenseStatus === 'inProgress') {
    return localize('driver.license.status.verifying');
  }
  if (licenseStatus === 'deleted') {
    return localize('driver.license.status.deleted');
  }
  return localize('driver.license.status.notuploaded');
};

export const formatIDStatus = (identityData: any, localize: any): string => {
  if (identityData) {
    switch (identityData?.status) {
      case 'verified':
        return localize('driver.id.status.verified');

      case 'open':
        return localize('driver.id.status.open');

      default:
        return localize('driver.id.status.open');
    }
  } else {
    return localize('driver.id.status.open');
  }
};

export const getEmailVerificationStatus = (user: any) => {
  const firstStepsChallenge = user?.challenges?.find(
    (challenge: any) => challenge.type === 'firstSteps'
  );

  const tasks = firstStepsChallenge?.tasks || [];

  return tasks?.find((task: any) => task?.screen === '/user/verify-email')
    ?.status;
};

export const getIDVerificationStatus = (user: any) => {
  const firstStepsChallenge = user?.challenges?.find(
    (challenge: any) => challenge.type === 'firstSteps'
  );

  const tasks = firstStepsChallenge?.tasks || [];

  return tasks?.find((task: any) => task?.screen === '/user/verify-id-document')
    ?.status;
};

export const getDLVerificationStatus = (user: any) => {
  const firstStepsChallenge = user?.challenges?.find(
    (challenge: any) => challenge.type === 'firstSteps'
  );

  const tasks = firstStepsChallenge?.tasks || [];

  return tasks?.find(
    (task: any) => task?.screen === '/user/verify-drivers-license'
  )?.status;
};

export const getTariffStatus = (user: any) => {
  const firstStepsChallenge = user?.challenges?.find(
    (challenge: any) => challenge.type === 'firstSteps'
  );

  const tasks = firstStepsChallenge?.tasks || [];

  return tasks?.find(
    (task: any) =>
      task?.screen === '/user/select-tariff' ||
      task?.text?.includes('selectTariff')
  )?.status;
};

export const isEmailVerificationRequired = (user: any) => {
  return getEmailVerificationStatus(user) != undefined;
};

export const isDLVerificationRequired = (user: any) => {
  return getDLVerificationStatus(user) != undefined;
};

export const isIDVerificationRequired = (user: any) => {
  return getIDVerificationStatus(user) != undefined;
};

export const isTariffEnabled = (user: any) => {
  return getTariffStatus(user) != undefined;
};

export const isTariffOpen = (user: any) => {
  return getTariffStatus(user) === 'open';
};

export const getNumberOfChallenges = (user: any) => {
  const firstStepsChallenge = user?.challenges?.find(
    (challenge: any) => challenge?.type === 'firstSteps'
  );

  const tasks = firstStepsChallenge?.tasks || [];
  return tasks?.length;
};

export const getNumberOfCompletedChallenges = (user: any) => {
  const firstStepsChallenge = user?.challenges?.find(
    (challenge: any) => challenge?.type === 'firstSteps'
  );

  const tasks = firstStepsChallenge?.tasks || [];
  const completedTasks =
    tasks?.filter(
      (task) =>
        ['completed'].includes(task?.status) ||
        (task?.status === 'inProgress' &&
          task?.screen === '/user/enter-address')
    ) || [];

  return completedTasks.length;
};

export const isJSON = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const isStaging = () => process.env.REACT_APP_STAGE !== 'production';
