import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateUser,
  withTextLocalizer,
  setPhoneNumber as setPhoneNumberRedux,
  LocalizePropType,
  selectPhoneCodes,
  isKYCApp,
} from 'getaway-data-layer';
import AddPhoneView from './AddPhoneView';
import {
  GA_TITLES,
  isDashboardScreen,
  navigateTo,
  reportGAEvent,
  showAlert,
} from '../../../../utils';
import { validatePhoneNumber } from '../../../../utils/validation';

export type PhoneItem = {
  value: string;
  dialCode: string | null;
  error: string;
};

type Props = {
  localize: LocalizePropType;
};

const AddPhoneContainer = ({ localize }: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const phoneCodes = useSelector(selectPhoneCodes);
  const [phoneNumber, setPhoneNumber] = useState<PhoneItem>({
    value: '',
    dialCode: null,
    error: '',
  });

  const oSuccess = () => {
    navigateTo(
      history,
      `/${isDashboardScreen() ? 'dashboard' : 'onboarding'}/verifyPhone`
    );
    reportGAEvent(GA_TITLES.ON_BOARDING.PHONE_SUBMITTED);
  };

  const onSubmit = () => {
    if (isValidNumber()) {
      dispatch(setPhoneNumberRedux(phoneNumber.value));
      dispatch(updateUser({ phoneNumber: phoneNumber.value.trim() }, oSuccess));
    }
  };

  const onPhoneNumberChanged = (value: string, data: any) => {
    let newVal = value.startsWith(data?.dialCode)
      ? value.slice(data?.dialCode?.length)
      : value;
    const zeroRegex = new RegExp(`^[0]*`, 'gi');
    newVal = newVal.replace(zeroRegex, '');
    const dialCodeRegex = new RegExp(`^${data?.dialCode}`, 'gi');
    newVal = newVal.replace(dialCodeRegex, '');
    newVal = `+${data?.dialCode}${newVal}`;

    setPhoneNumber({
      dialCode: data?.dialCode,
      value: newVal,
      error: '',
    });
  };

  const isValidNumber = () => {
    const error = validatePhoneNumber(
      phoneNumber.value?.slice(phoneNumber.dialCode?.length)
    );
    setPhoneNumber({
      dialCode: phoneNumber.dialCode,
      value: phoneNumber.value,
      error,
    });
    return !error;
  };

  const onClose = () => {
    if (isDashboardScreen()) {
      navigateTo(history, '/settings');
    } else {
      showAlert(
        '',
        localize('cancel.FirstSteps.dialog.message'),
        localize('cancel.FirstSteps.dialog.yes'),
        () => navigateTo(history, isKYCApp() ? '/settings' : '/map'),
        localize('cancel.FirstSteps.dialog.no'),
        () => {},
        true
      );
    }
  };

  return (
    <AddPhoneView
      phoneNumber={phoneNumber}
      localize={localize}
      phoneCodes={phoneCodes}
      onClose={onClose}
      onSubmit={onSubmit}
      isValidNumber={isValidNumber}
      onPhoneNumberChanged={onPhoneNumberChanged}
    />
  );
};

export default withTextLocalizer(AddPhoneContainer);
