import * as React from 'react';
import './HandOverCheckbox.less';
import BrandIcon from '../../../../components/BrandIcon/BrandIcon';
import { LocalizePropType, getLightBrandColor } from 'getaway-data-layer';

type Props = {
  localize: LocalizePropType;
  name: string;
  title: string;
  selected: boolean;
  onPress: any;
  disabled?: boolean;
  value?: string;
  icon?: any;
  onPressOption?: () => void;
  containerStyles?: any;
};

const HandOverCheckbox = ({
  localize,
  name,
  title,
  selected,
  onPress,
  disabled,
  value,
  icon,
  onPressOption,
  containerStyles,
}: Props) => (
  <>
    <div
      className="hand-over-checkbox-container"
      // @ts-ignore
      style={{
        ...containerStyles,
        ...(selected
          ? { backgroundColor: getLightBrandColor() }
          : icon && !selected
          ? {
              borderBottomWidth: 0,
            }
          : {}),
      }}
      onClick={disabled ? () => {} : () => onPress(name)}
    >
      <div className="hand-over-checkbox-row">
        <BrandIcon
          name={
            selected
              ? 'selected'
              : disabled
              ? 'iconCheckboxDisabledGrey'
              : 'iconCheckboxInactive'
          }
          style={{ width: 24, height: 24, marginRight: 8 }}
        />
        <div className="hand-over-checkbox-name">{title}</div>
      </div>
      {!selected && (name === 'key' || name === 'card') && (
        <BrandIcon
          name="info"
          style={{
            width: 24,
            height: 24,
            position: 'absolute',
            right: 8,
            top: 15,
          }}
        />
      )}
    </div>
    {!selected && icon && (
      <div
        className="hand-over-checkbox-optionTouchable"
        onClick={onPressOption}
      >
        <div className="hand-over-checkbox-detailedRow">
          <div className="hand-over-checkbox-row">
            <BrandIcon
              name={icon}
              style={{ width: 20, height: 24, marginLeft: 48 }}
            />
            {value && <div className="hand-over-checkbox-value">{value}</div>}
          </div>
          <div className="hand-over-checkbox-row">
            <div className="hand-over-checkbox-option">
              {localize(
                name === 'insideArea'
                  ? 'handover.location.option'
                  : 'handover.lowFuel.option'
              )}
            </div>
            <BrandIcon name="arrowRight" style={{ width: 16, height: 16 }} />
          </div>
        </div>
      </div>
    )}
  </>
);

HandOverCheckbox.defaultProps = {
  disabled: false,
  value: null,
  icon: null,
  onPressOption: () => {},
  containerStyles: {},
};

export default HandOverCheckbox;
