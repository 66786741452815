import React from 'react';
import {
  formatMmToCm,
  formatKgToTone,
  withTextLocalizer,
  LocalizePropType,
  DriverCarData,
  isMBA,
} from 'getaway-data-layer';
import { dimensionsCarousel } from '../../../assets/brandImages';
import './CarouselItem.less';
import Plate from '../Plate/Plate';
import BrandIcon from '../BrandIcon/BrandIcon';

export const CAROUSEL_ITEM_WIDTH = window.innerWidth * 0.92;
export const CAROUSEL_ITEM_HEIGHT = 220;

type Props = {
  localize: LocalizePropType;
  car: DriverCarData;
  onClickItem: () => void;
  fleetStyle: string;
  isExpert: boolean;
  isOBI: boolean;
};

const carDimensionsImage = {
  spp: '/images/dimensionsSPPCarousel.png',
  expert: '/images/dimensionsExpertCarousel.png',
  obi: '/images/dimensionsOBICarousel.png',
};

const cardLogos = {
  spp: '/images/sppCardLogo.png',
  expert: '/images/expertCardLogo.png',
  obi: '/images/obiMapLogo.png',
};

const CarouselItem = ({
  localize,
  car: {
    height,
    width,
    length,
    loadCapacity,
    gear,
    fuelLevel,
    seats,
    plate,
    driverPrice,
    images,
    vehicleType,
  },
  onClickItem,
  fleetStyle,
  isExpert,
  isOBI,
}: Props) => {
  const carCarosuelPrice = driverPrice.description.split('+');
  return (
    <div
      className="carousel-item-container"
      style={{ width: CAROUSEL_ITEM_WIDTH, height: CAROUSEL_ITEM_HEIGHT }}
      onClick={onClickItem}
    >
      <div className="carousel-item-dimensions">
        {vehicleType === 'passenger' ? (
          <img
            style={{
              width: CAROUSEL_ITEM_WIDTH * 0.48,
              height: CAROUSEL_ITEM_HEIGHT * 0.55,
              marginRight: 4,
              marginTop: 4,
              border: '3px solid white',
              borderRadius: '8px',
              objectFit: 'cover',
            }}
            className="carousel-img"
            src={images![0]}
            alt="carImage"
          />
        ) : (
          <img
            style={{
              width: CAROUSEL_ITEM_WIDTH * 0.47,
              height: CAROUSEL_ITEM_HEIGHT * 0.55,
              marginBottom: 8,
            }}
            src={carDimensionsImage?.[fleetStyle] || dimensionsCarousel}
            alt="dimensionsCarousel"
          />
        )}
        {vehicleType !== 'passenger' && (
          <>
            {/* @ts-ignore */}
            {height !== 0 && height && (
              <div className="carousel-item-heightLabel">
                {formatMmToCm(height).split('m')}
              </div>
            )}
            {/* @ts-ignore */}
            {width !== 0 && width && (
              <div className="carousel-item-widthLabel">
                {formatMmToCm(width).split('m')}
              </div>
            )}
            {/* @ts-ignore */}
            {length !== 0 && length && (
              <div className="carousel-item-lengthLabel">
                {formatMmToCm(length).split('m')}
              </div>
            )}
            {/* @ts-ignore */}
            {loadCapacity !== 0 && loadCapacity && (
              <div className="carousel-item-capacity">
                {formatKgToTone(loadCapacity)}
              </div>
            )}
          </>
        )}
      </div>
      {['obi', 'expert', 'spp'].includes(fleetStyle) && (
        <div className="carousel-logo-container">
          <img className="carousel-logo" src={cardLogos[fleetStyle]} />
        </div>
      )}
      <div
        className="carousel-item-white-background"
        style={{
          width: CAROUSEL_ITEM_WIDTH - 32,
          height: CAROUSEL_ITEM_HEIGHT - 50,
        }}
      >
        <div className="carousel-item-price-wrapper">
          <div>
            <div className="carousel-item-price-pre">{localize('from')}</div>
            <div
              className="carousel-item-price"
              style={isMBA() || isOBI || isExpert ? { fontSize: '17px' } : {}}
            >
              {carCarosuelPrice[0]}
            </div>
            {carCarosuelPrice[1] && (
              <div
                className="carousel-item-price-post"
                style={isOBI ? { fontSize: '11px' } : {}}
              >
                {`+${carCarosuelPrice[1]}`}
              </div>
            )}
          </div>
        </div>
        <div
          className="carousel-item-bottom-wrapper"
          style={!carCarosuelPrice[1] ? { marginTop: 45 } : {}}
        >
          <Plate carPlate={plate!} />
          {gear && (
            <div className="carousel-item-row">
              <BrandIcon name="gearType" style={{ width: 14, height: 14 }} />
              <div className="carousel-item-detail">
                {localize(`car.details.gear.${gear}`)}
              </div>
            </div>
          )}
          {/* @ts-ignore */}
          {fuelLevel !== '' && (
            <div className="carousel-item-row">
              <BrandIcon
                name={fuelLevel! < 25 ? 'redCarFuel' : 'carFuel'}
                style={{ width: 14, height: 14 }}
              />
              <div
                className="carousel-item-detail"
                style={fuelLevel! < 25 ? { color: 'red' } : {}}
              >
                {`${fuelLevel}%`}
              </div>
            </div>
          )}
          {seats && (
            <div className="carousel-item-row">
              <BrandIcon name="carSeat" style={{ width: 14, height: 14 }} />
              <div className="carousel-item-detail">{seats}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default withTextLocalizer(CarouselItem);
