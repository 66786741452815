import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import {
  getCountryListWeb,
  isKYCApp,
  LocalizePropType,
  selectBrandSettings,
  selectUser,
  updateUser,
  withTextLocalizer,
} from 'getaway-data-layer';
import UpdateAddressView from './UpdateAddressView';
import {
  GA_TITLES,
  isDashboardScreen,
  navigateTo,
  reportGAEvent,
  showAlert,
} from '../../../../utils';
import { AddressModal, AddressSchema } from '../../../../validation';
import AddressAutoCompleteModal from './AddressSearchModal';

interface Values {
  streetName: string;
  streetNumber: string;
  postalCode: string;
  city: string;
  country: string;
}

type Props = {
  localize: LocalizePropType;
};

const UpdateAddressContainer = ({ localize }: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user: any = useSelector(selectUser);
  const brandSettings = useSelector(selectBrandSettings);

  const [country, setCountry] = useState(
    user?.address?.country ||
      brandSettings?.defaultCountryCode?.toUpperCase() ||
      'DE'
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const supportedCountries = useMemo(
    () =>
      getCountryListWeb(localize, brandSettings?.allowedUserAddressCountries),
    []
  );

  const onCountryChange = (value: any) => {
    setCountry(value);
  };

  const onSuccess = () => {
    if (isKYCApp()) {
      history.goBack();
    } else if (isDashboardScreen()) {
      navigateTo(history, `/settings`);
    } else {
      navigateTo(history, `/onboarding/selectPayment`);
    }

    reportGAEvent(GA_TITLES.ON_BOARDING.ADDRESS_SUBMITTED);
  };

  const onSubmit = (values: Values) => {
    dispatch(
      updateUser(
        {
          address: {
            streetName: values?.streetName.trim(),
            streetNumber: values?.streetNumber.trim(),
            city: values?.city.trim(),
            postalCode: values?.postalCode.trim(),
            country: values?.country.trim(),
          },
        },
        onSuccess
      )
    );
  };

  const onClose = () => {
    if (isDashboardScreen()) {
      history.goBack();
    } else {
      showAlert(
        '',
        localize('cancel.FirstSteps.dialog.message'),
        localize('cancel.FirstSteps.dialog.yes'),
        () => navigateTo(history, isKYCApp() ? '/settings' : '/map'),
        localize('cancel.FirstSteps.dialog.no'),
        () => {},
        true
      );
    }
  };

  const AddressFormik = useFormik({
    initialValues: AddressModal(user),
    validationSchema: AddressSchema(localize, country),
    onSubmit,
  });

  const onCloseModal = () => {
    setIsModalOpen(false);
  };

  const onLocationSelected = (location: any) => {
    AddressFormik.setValues(location);
    setIsModalOpen(false);
  };

  const onSearchAddressClicked = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <AddressAutoCompleteModal
        countryRestriction={AddressFormik.values.country}
        isModalOpen={isModalOpen}
        onCloseModal={onCloseModal}
        onLocationSelected={onLocationSelected}
      />
      <UpdateAddressView
        localize={localize}
        onClose={onClose}
        formik={AddressFormik}
        supportedCountries={supportedCountries}
        onCountryChange={onCountryChange}
        onSearchAddressClicked={onSearchAddressClicked}
      />
    </>
  );
};

export default withTextLocalizer(UpdateAddressContainer);
