import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  addPayment,
  addPaymentDetails,
  clearDialog,
  getPaymentMethods,
  isKYCApp,
  LocalizePropType,
  selectBrandSettings,
  selectUser,
  withTextLocalizer,
} from 'getaway-data-layer';
import AdyenCheckout from '@adyen/adyen-web';
import '@adyen/adyen-web/dist/adyen.css';
import {
  isDashboardScreen,
  navigateTo,
  showAlert,
  getAdyenChcekoutConfigs,
  isIDVerificationRequired,
} from '../../../utils';
import CCAdyenComponentView from './CCAdyenComponentView';

type Props = {
  localize: LocalizePropType;
};

const CCAdyenComponent = ({ localize }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user: any = useSelector(selectUser);
  const brandSettings = useSelector(selectBrandSettings);

  const onClose = () => {
    if (isDashboardScreen()) {
      navigateTo(history, '/settings');
    } else {
      showAlert(
        '',
        localize('cancel.FirstSteps.dialog.message'),
        localize('cancel.FirstSteps.dialog.yes'),
        () => navigateTo(history, isKYCApp() ? '/settings' : '/map'),
        localize('cancel.FirstSteps.dialog.no'),
        () => {},
        true
      );
    }
  };

  const onSubmitDropinPayment = (state: any, action: any) => {
    // submit CC
    if (state?.data?.paymentMethod?.holderName) {
      dispatch(
        addPayment(
          {
            card: state.data.paymentMethod,
          },
          () => {
            navigateTo(history, '/settings');
          }
        )
      );
    }
  };

  const onSubmitDropinPaymentDetails = (state: any, dropin: any) => {
    dispatch(
      addPaymentDetails(state.data, (response) => {
        if (response.action) {
          // Drop-in handles the action object from the /payments response
          dropin.handleAction(response.action);
        } else {
          // Your function to show the final result to the shopper
          dropin.setStatus('success');
          dispatch(clearDialog());
          if (isDashboardScreen()) {
            navigateTo(history, '/settings');
          } else {
            if (isIDVerificationRequired(user)) {
              navigateTo(history, `/onboarding/IDOnboarding`);
            } else {
              navigateTo(history, '/onboarding/DLOnboarding');
            }
          }
        }
      })
    );
  };

  const initializeAdyenDropIn = async (paymentMethods: any) => {
    // Create an instance of AdyenCheckout using the configuration object.
    // @ts-ignore
    const checkout = new AdyenCheckout(
      getAdyenChcekoutConfigs(
        user.locale,
        user.address?.country || brandSettings?.defaultCountryCode,
        brandSettings?.adyenClientKey!,
        paymentMethods,
        onSubmitDropinPayment,
        onSubmitDropinPaymentDetails
      )
    );

    const dropin = checkout.create('dropin').mount('#card-div');
  };

  useEffect(() => {
    setTimeout(() => {
      dispatch(
        getPaymentMethods((paymentMethods) => {
          initializeAdyenDropIn(paymentMethods);
        })
      );
    }, 50);
  }, []);

  return (
    <>
      <CCAdyenComponentView localize={localize} onClose={onClose} />
    </>
  );
};

export default withTextLocalizer(CCAdyenComponent);
