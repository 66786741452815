/* eslint react/no-unknown-property: 0 */
import React, { useState, useEffect, useMemo } from 'react';
import GoogleMapReact from 'google-maps-react-markers';
import cn from 'classnames';
import {
  setMapContent,
  selectAvailableCars,
  selectUnavailableCars,
  selectMapContent,
  setSelectedCar,
  selectSelectedCar,
  setLastKnownLocation,
  selectAreas,
  selectLastKnownLocation,
  isRentalOngoing,
  selectRentalData,
  gasStations,
  nearestGasStation,
  locationFromString,
  isInsidePolygon,
  isBefore24hrs,
  setRegionChangeNo,
  selectRegionChangeNo,
  setMapCenterLocation,
  setRadius,
  distanceBetweenLocationsInKm,
  setMapBoundaries,
  selectMapCenterLocation,
  selectMapBoundaries,
  dateAndTimeStringFromDate,
  selectUserAddress,
  setUserAddress,
  isUBQ,
  selectPreSignupBooking,
  setShowHomeWT,
} from 'getaway-data-layer';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import mapStyles from './Map.styles';
import './MapView.less';
import {
  getPositionErrorMessage,
  trackLocation,
  svgUserMarker,
  getCurrentPosition,
  getPloyCoordinates,
  calculateAndDisplayRoute,
  isInsideArea,
  navigateTo,
} from '../../../utils';
import BrandIcon from '../../components/BrandIcon/BrandIcon';
import {
  annotationToMapContent,
  mapFuelIconStyles,
  navigateIconStyles,
  mapFlagIconStyles,
  getMapDefaultCenter,
} from './map.helpers';
import { availableMapMarker, mapNavigate } from '../../../assets/brandImages';
import { useQuery } from '../../../hooks/useQuery';
import {
  getBrandColor,
  getLightBrandColor,
  isMBA,
} from 'getaway-data-layer/src/helpers/brandHelpers';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const availableMarkers = {
  spp: '/images/availableSPPMarker.png',
  expert: '/images/availableOBIMarker.png',
  obi: '/images/availableOBIMarker.png',
  ubq: availableMapMarker,
};

const mapPinLogos = {
  spp: '/images/sppMapLogo.png',
  expert: '/images/expertMapLogo.png',
  obi: '/images/obiMapLogo.png',
};

const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const MapView = ({
  localize,
  closeNav,
  activeBookingsList,
  onClickShowAvailability,
}) => {
  const location = useLocation();
  const history = useHistory();
  const query = useQuery();
  const [mapRef, setMapRef] = useState(null);
  const [mapsRef, setMapsRef] = useState(null);
  const [mapZoom, setMapZoom] = useState(null);
  const [selectedAreaPolyRef, setSelectedAreaPolyRef] = useState(null);
  const [directionsServiceRef, setDirectionsServiceRef] = useState(null);
  const [directionsRendererRef, setDirectionsRendererRef] = useState(null);
  const [userLocation, setUserLocation] = useState(null);
  const [selectedGasStationId, setSelectedGasStationId] = useState(null);
  const dispatch = useDispatch();
  const rentalData = useSelector(selectRentalData);
  const selectedCar = useSelector(selectSelectedCar);
  const lastKnownLocation = useSelector(selectLastKnownLocation);
  const availableCarsObj = useSelector(selectAvailableCars);
  const unAvailableCarsObj = useSelector(selectUnavailableCars);
  const availableCarsIds = Object.keys(availableCarsObj);
  const availableCars = Object.values(availableCarsObj);
  const unAvailableCars = Object.values(unAvailableCarsObj);
  const mapContent = useSelector(selectMapContent);
  const areas = useSelector(selectAreas);
  const regionChangeNo = useSelector(selectRegionChangeNo);
  const mapCenterLocation = useSelector(selectMapCenterLocation);
  const mapBoundaries = useSelector(selectMapBoundaries);
  const userAddress = useSelector(selectUserAddress);
  const preSignupBooking = useSelector(selectPreSignupBooking);

  const onPressMap = (id, type) => {
    if (!id && type === 'none') {
      dispatch(setMapContent(null, mapContent.cardType, null));
      dispatch(setSelectedCar(null));
      setSelectedGasStationId(null);
      closeNav();

      const params = new URLSearchParams(location.search);
      params.delete('id');
      history.push({ search: params.toString() });
    }
  };

  const onAnnotationPress = (id, type) => {
    if (mapContent.id === id) return;
    const car =
      availableCars.find((item) => item.id === id) ||
      unAvailableCars.find((item) => item.id === id);

    if (car) {
      const cont = annotationToMapContent(id, type, mapContent.cardType);
      dispatch(setMapContent(cont.mapType, cont.cardType, cont.id));
      dispatch(setSelectedCar(car));
      navigateTo(history, '/map');
      // Handle map car deeplink as query param
      const params = new URLSearchParams(location.search);
      params.set('id', car.id);
      history.push({ search: params.toString() });
    } else if (type === 'gasStation') {
      const selectedGasStation = gasStations.find(
        (gasStation) => gasStation.id === id
      );
      if (selectedGasStation) {
        const cont = annotationToMapContent(
          id,
          'gasStation',
          mapContent.cardType
        );
        dispatch(setMapContent(cont.mapType, cont.cardType, cont.id));
        setSelectedGasStationId(selectedGasStation.id);
      }
    } else {
      const cont = annotationToMapContent(id, type, mapContent.cardType);
      dispatch(setMapContent(cont.mapType, cont.cardType, cont.id));
    }
  };

  const handleApiLoaded = (map, maps) => {
    setMapRef(map);
    setMapsRef(maps);
  };

  const onBoundsChange = ({ zoom, bounds, center }) => {
    dispatch(
      setMapCenterLocation({
        latitude: center[1],
        longitude: center[0],
      })
    );

    setMapZoom(zoom);
    if (bounds != null) {
      const ne = bounds.getNorthEast();
      const sw = bounds.getSouthWest();

      const se = {
        latitude: sw.lat(),
        longitude: ne.lng(),
      };
      const nw = {
        latitude: ne.lat(),
        longitude: sw.lng(),
      };

      const boundaries = [
        {
          latitude: ne.lat(),
          longitude: ne.lng(),
        },
        {
          latitude: se.latitude,
          longitude: se.longitude,
        },
        {
          latitude: sw.lat(),
          longitude: sw.lng(),
        },
        {
          latitude: nw.latitude,
          longitude: nw.longitude,
        },
      ];
      dispatch(setMapBoundaries(boundaries));
      if (mapCenterLocation) {
        const radius = Math.ceil(
          distanceBetweenLocationsInKm(mapCenterLocation, boundaries[0]) * 1000
        );
        dispatch(setRadius(radius));
      }
    }
  };

  const onPressLocateMe = () => {
    if (mapRef) {
      if (userLocation) {
        mapRef.panTo(userLocation);
      } else {
        getCurrentPosition({
          onSuccess: ({ coords: { latitude: lat, longitude: lng } }) => {
            mapRef.panTo({ lat, lng });
          },
          onError: (err) =>
            alert(`Error: ${getPositionErrorMessage(err.code) || err.message}`),
        });
      }
    }
  };

  const onReturnLocationPressed = () => {
    if (mapContent.mapType === 'rentalReturnLocation') {
      dispatch(
        setMapContent(
          'rentalDriverCar',
          mapContent.cardType,
          rentalData.carData.id
        )
      );
      selectedAreaPolyRef.setPath([]);
      selectedAreaPolyRef.setMap(mapRef);
      directionsRendererRef.setDirections({ routes: [] });
    } else {
      dispatch(
        setMapContent(
          'rentalReturnLocation',
          mapContent.cardType,
          rentalData.carData.id
        )
      );
      const selectedArea = areas?.find(
        (area) => area.id === rentalData.carData.areaId
      );
      const coords = getPloyCoordinates(selectedArea?.geoJSONData);
      selectedAreaPolyRef.setPath(coords);
      selectedAreaPolyRef.setMap(mapRef);
      mapRef.panTo(coords[0]);
      if (
        (lastKnownLocation || userLocation) &&
        distanceBetweenLocationsInKm(
          lastKnownLocation || {
            latitude: userLocation.lat,
            longitude: userLocation.lng,
          },
          {
            latitude: coords[0].lat,
            longitude: coords[0].lng,
          }
        ) <= 10
      ) {
        calculateAndDisplayRoute(
          directionsServiceRef,
          directionsRendererRef,
          userLocation || {
            lat: lastKnownLocation.latitude,
            lng: lastKnownLocation.longitude,
          },
          coords[0]
        );
      }
    }
  };

  const onNearestGasStationPressed = () => {
    if (mapContent.mapType === 'rentalNearestGasStation') {
      dispatch(
        setMapContent(
          'rentalDriverCar',
          mapContent.cardType,
          rentalData.carData.id
        )
      );
    } else {
      const station = nearestGasStation(
        locationFromString(rentalData.carData.position),
        gasStations
      );
      dispatch(
        setMapContent(
          'rentalNearestGasStation',
          mapContent.cardType,
          station?.id
        )
      );
    }
  };

  const getUserCountry = (location) => {
    if (mapsRef && mapRef && location && !userAddress) {
      const geoCoder = new mapsRef.Geocoder();
      geoCoder
        .geocode({ location: location })
        .then((response) => {
          if (response?.results[0]) {
            const address = response?.results[0].formatted_address;
            dispatch(setUserAddress(address));
            if (address.includes('Spain')) {
              mapRef.panTo({ lat: 39.5696, lng: 2.6502 });
            }
          }
        })
        .catch(() => {});
    }
  };

  const setInitialMapQueryLocation = () => {
    const latLng = query.get('ll')?.split(',').map(Number);
    const zoom = parseInt(query.get('z'));

    if (
      latLng &&
      latLng.length === 2 &&
      !isNaN(latLng[0]) &&
      !isNaN(latLng[1]) &&
      !isNaN(zoom)
    ) {
      const [latitude, longitude] = latLng;

      if (mapRef) {
        dispatch(setShowHomeWT(false));
        mapRef.setCenter({ lat: latitude, lng: longitude });
        mapRef.setZoom(zoom);
        dispatch(setMapCenterLocation({ latitude, longitude }));
        setMapZoom(zoom);
      }
    }
  };

  useEffect(() => {
    if (mapsRef && mapRef) {
      setInitialMapQueryLocation();
      mapRef?.addListener('click', () => {
        onPressMap(event?.target?.id, 'none');
      });
      setDirectionsServiceRef(new mapsRef.DirectionsService());
      setDirectionsRendererRef(
        new mapsRef.DirectionsRenderer({
          suppressMarkers: true,
          polylineOptions: {
            strokeColor: '#1b1b1b',
            strokeOpacity: 1,
            strokeWeight: 2,
          },
        })
      );
      const userMarker = new mapsRef.Marker({
        mapRef,
        position: { lat: 52.52, lng: 13.388 },
        icon: svgUserMarker,
      });
      setTimeout(
        () =>
          trackLocation({
            onSuccess: ({ coords: { latitude: lat, longitude: lng } }) => {
              userMarker.setPosition({ lat, lng });
              userMarker.setMap(mapRef);
              setUserLocation({ lat, lng });
              dispatch(
                setLastKnownLocation({
                  latitude: lat,
                  longitude: lng,
                })
              );
              getUserCountry({ lat, lng });
            },
            onError: (err) => {},
          }),
        4000
      );
      const selectedAreaPoly = new mapsRef.Polygon({
        paths: [],
        strokeColor: 'black',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: getLightBrandColor(),
        fillOpacity: 0.35,
      });
      setSelectedAreaPolyRef(selectedAreaPoly);
    }
  }, [mapsRef, mapRef]);

  useEffect(() => {
    if (mapRef) {
      if (selectedCar) {
        mapRef.panTo(locationFromString(selectedCar.position, ',', true, true));
        const selectedArea = areas?.find(
          (area) => area.id === selectedCar.areaId
        );
        const coords = getPloyCoordinates(selectedArea?.geoJSONData);
        if (selectedArea) {
          const insideArea = isInsideArea(
            locationFromString(selectedCar.position),
            selectedArea
          );
          selectedAreaPolyRef.setOptions({
            strokeColor: insideArea
              ? getBrandColor(selectedArea.fleetStyle)
              : 'red',
            fillColor: insideArea
              ? getLightBrandColor(selectedArea.fleetStyle)
              : 'rgba(227,7, 19, 0.13)',
          });
          selectedAreaPolyRef.setPath(coords);
          selectedAreaPolyRef.setMap(mapRef);
        }

        if (userLocation) {
          directionsRendererRef.setMap(mapRef);
          if (
            distanceBetweenLocationsInKm(
              lastKnownLocation || {
                latitude: userLocation.lat,
                longitude: userLocation.lng,
              },
              {
                latitude: coords[0].lat,
                longitude: coords[0].lng,
              }
            ) <= 10
          ) {
            calculateAndDisplayRoute(
              directionsServiceRef,
              directionsRendererRef,
              userLocation,
              locationFromString(selectedCar.position, ',', true, true)
            );
          }
        }
      } else if (!isRentalOngoing(rentalData)) {
        selectedAreaPolyRef.setPath([]);
        selectedAreaPolyRef.setMap(mapRef);
        directionsRendererRef.setDirections({ routes: [] });
      }
    }
  }, [selectedCar]);

  useEffect(() => {
    const carToSelect = availableCars?.find(
      (item) => item?.id === mapContent?.id
    );
    if (mapContent?.id && !selectedCar && carToSelect) {
      dispatch(setSelectedCar(carToSelect));
    }
  }, [mapContent.id, availableCars]);

  useEffect(() => {
    dispatch(setRegionChangeNo(regionChangeNo + 1));
  }, [mapCenterLocation, mapZoom]);

  useEffect(() => {
    if (query.get('id')) {
      const cont = annotationToMapContent(
        query.get('id'),
        'availableCar',
        mapContent.cardType
      );
      dispatch(setMapContent(cont.mapType, cont.cardType, cont.id));
    }
  }, []);

  const renderCallout = useMemo(() => {
    if (!selectedCar) return null;
    const position = locationFromString(selectedCar.position);
    const selectedArea = areas?.find((area) => area.id === selectedCar.areaId);
    const insideArea = isInsideArea(
      locationFromString(selectedCar.position),
      selectedArea
    );
    const isBonus = (selectedCar.fuelLevel < 25 || !insideArea) && !isMBA();
    const isAvailableUntil =
      availableCarsIds.includes(selectedCar?.id) &&
      ((selectedCar?.backBy && isBefore24hrs(new Date(selectedCar.backBy))) ||
        (selectedCar?.availableUntil &&
          isBefore24hrs(new Date(selectedCar.availableUntil))));
    const returnTime = selectedCar?.backBy || selectedCar?.availableUntil;
    const isRented = selectedCar.status === 'RENTED';

    return isBonus || isAvailableUntil || isRented ? (
      <div
        className={cn(
          'callout-container',
          { isBonus },
          { isAvailableUntil },
          { isRented }
        )}
        lat={position.latitude}
        lng={position.longitude}
      >
        {isRented ? (
          <div className="text-callout-container">
            <div className="text-callout-text">
              {localize('map.rented.callout')}
              <span
                className="text-callout-link"
                onClick={onClickShowAvailability}
              >
                {localize('callout.show.availability')}
              </span>
            </div>
          </div>
        ) : isBonus ? (
          <div className="bonus-callout-container">
            <BrandIcon
              name="gift"
              style={{ width: 18, height: 18, margin: '0 3px' }}
            />
            <div className="bonus-callout-text">
              {selectedCar.fuelLevel < 25 && !insideArea
                ? localize('map.callout.bonus.lowAndOut')
                : localize('map.callout.bonus.lowFuel')}
            </div>
            <BrandIcon
              name="giftWarning"
              style={{ width: 18, height: 18, margin: '0 3px' }}
            />
          </div>
        ) : isAvailableUntil ? (
          <div className="text-callout-container">
            <div className="text-callout-text">
              {localize(
                'map.car.released.callout',
                returnTime
                  ? dateAndTimeStringFromDate(new Date(returnTime), localize)
                  : ''
              )}
              <span
                className="text-callout-link"
                onClick={onClickShowAvailability}
              >
                {localize('callout.show.availability')}
              </span>
            </div>
          </div>
        ) : null}
        <div className="callout-arrow" />
      </div>
    ) : null;
  }, [selectedCar]);

  const renderAvailableCars = useMemo(() => {
    const carsInMapBoundaries = mapBoundaries
      ? availableCars.filter((car) =>
          isInsidePolygon(locationFromString(car.position), mapBoundaries)
        )
      : availableCars;
    return !isRentalOngoing(rentalData)
      ? [
          ...carsInMapBoundaries.filter((car) => car?.id !== selectedCar?.id),
          selectedCar,
        ]
          ?.filter(
            (car) =>
              car?.vehicleConnection !== 'geoTab' && car?.id !== mapContent?.id
          )
          .map((car) => {
            if (car) {
              const position = locationFromString(car.position);
              const fleetStyle =
                areas?.find((area) => car?.areaId === area?.id)?.fleetStyle ||
                'default';
              return (
                <div
                  key={`available-car-marker-${car.id}`}
                  className="car-marker-wrapper"
                  lat={position.latitude}
                  lng={position.longitude}
                  onClick={() => onAnnotationPress(car.id, 'availableCar')}
                >
                  <img
                    alt="availableMapMarker"
                    src={availableMarkers?.[fleetStyle] || availableMapMarker}
                    style={{ height: '50px', width: '50px' }}
                  />
                  {!(isUBQ() && mapZoom < 11) && (
                    <div id={car.id} className="marker-text">
                      {car.driverPrice.map}
                    </div>
                  )}
                  {isUBQ() && mapZoom < 11 && (
                    <img
                      id={car.id}
                      className={
                        fleetStyle === 'obi' ? 'marker-obi-img' : 'marker-img'
                      }
                      src={
                        mapPinLogos?.[fleetStyle] || '/images/ubqMapLogo.png'
                      }
                    />
                  )}
                </div>
              );
            }
            return null;
          })
      : null;
  }, [rentalData, availableCars]);

  const renderUnAvailableCars = useMemo(() => {
    if (isUBQ() && mapZoom < 11) return null;
    return !isRentalOngoing(rentalData)
      ? unAvailableCars
          ?.filter(
            (car) =>
              car.status !== 'RELEASE_READY' &&
              car.vehicleConnection !== 'geoTab' &&
              car.id !== mapContent?.id
          )
          .map((car) => {
            const position = locationFromString(car.position);
            return (
              <div
                key={`un-available-car-marker-${car.id}`}
                className="car-marker-wrapper"
                lat={position.latitude}
                lng={position.longitude}
                onClick={(e) => onAnnotationPress(car.id, 'unavailableCar')}
              >
                <img
                  alt="unAvailableCarMarker"
                  src="/images/unavailableCarMarker.png"
                  style={{ height: '50px', width: '50px' }}
                />
                <div id={car.id} className="marker-text">
                  {car.driverPrice.map}
                </div>
              </div>
            );
          })
      : null;
  }, [rentalData, unAvailableCars]);

  const renderRentalAnnotation =
    isRentalOngoing(rentalData) &&
    mapContent.mapType !== 'rentalReturnLocation' ? (
      <div
        id={rentalData.carData.id}
        className="selected-car-marker-wrapper"
        key={`un-available-car-marker-${rentalData.carData.id}`}
        lat={locationFromString(rentalData.carData.position).latitude}
        lng={locationFromString(rentalData.carData.position).longitude}
      >
        <img
          alt="unAvailableCarMarker"
          src="/images/unavailableCarMarker.png"
          style={{ height: '70px', width: '70px' }}
        />
        <div className="selected-marker-text">
          {rentalData.carData.driverPrice.map}
        </div>
      </div>
    ) : null;

  const renderGasStations = useMemo(() => {
    if (mapZoom < 11) return null;
    const stationsInMapBoundaries = mapBoundaries
      ? gasStations.filter((station) =>
          isInsidePolygon(locationFromString(station.location), mapBoundaries)
        )
      : gasStations;
    return stationsInMapBoundaries.map((station) => {
      const isSelectedStation = station.id === selectedGasStationId;
      return (
        <div
          key={`gas-station-${station.id}`}
          className={
            isSelectedStation
              ? 'selected-gas-marker-wrapper'
              : 'gas-marker-wrapper'
          }
          lat={locationFromString(station.location).latitude}
          lng={locationFromString(station.location).longitude}
          onClick={() =>
            !isSelectedStation && onAnnotationPress(station.id, 'gasStation')
          }
        >
          <img
            id={station.id}
            alt="gasStation"
            src={
              isSelectedStation
                ? '/images/selectedGasStationMarker.png'
                : '/images/gasStationMarker.png'
            }
            style={{
              height: isSelectedStation ? '49px' : '33px',
              width: isSelectedStation ? '38px' : '24px',
            }}
          />
        </div>
      );
    });
  }, [selectedGasStationId, mapZoom, mapBoundaries]);

  const renderSelectedCar = useMemo(() => {
    const selectedAvailable = availableCars?.find(
      (car) => car?.id === mapContent?.id
    );
    const selectedUnavailable = unAvailableCars?.find(
      (car) => car?.id === mapContent?.id
    );
    const selected = selectedAvailable || selectedUnavailable;
    if (!isRentalOngoing(rentalData) && selected) {
      const position = locationFromString(selected.position);
      const fleetStyle =
        areas?.find((area) => selectedAvailable?.areaId === area?.id)
          ?.fleetStyle || 'default';
      return (
        <div
          key={`available-car-marker-${selected.id}`}
          className="selected-car-marker-wrapper"
          lat={position.latitude}
          lng={position.longitude}
        >
          {selectedAvailable ? (
            <img
              alt="availableMapMarker"
              src={availableMarkers?.[fleetStyle] || availableMapMarker}
              style={{ height: '70px', width: '70px' }}
            />
          ) : (
            <img
              alt="unAvailableCarMarker"
              src="/images/unavailableCarMarker.png"
              style={{ height: '70px', width: '70px' }}
            />
          )}
          <img
            id={selected.id}
            className="selected-marker-img"
            src={selected?.images?.[0]}
          />
        </div>
      );
    }
    return null;
  }, [rentalData, availableCars]);

  console.log('mapRef', mapRef);
  console.log('mapsRef', mapsRef);
  return (
    <>
      <div onClick={onPressLocateMe}>
        <img
          alt="mapNavigate"
          src={mapNavigate}
          // @ts-ignore
          style={{
            ...navigateIconStyles,
            top:
              activeBookingsList?.length || preSignupBooking ? '95px' : '70px',
          }}
        />
      </div>
      {['drivingCar', 'parkingCar'].includes(mapContent?.cardType) && (
        <>
          <div onClick={onReturnLocationPressed}>
            <BrandIcon
              name={
                mapContent?.mapType === 'rentalReturnLocation'
                  ? 'mapFlagActive'
                  : 'mapFlag'
              }
              style={mapFlagIconStyles}
            />
          </div>
          <div onClick={onNearestGasStationPressed}>
            <BrandIcon
              name={
                mapContent?.mapType === 'rentalNearestGasStation'
                  ? 'mapFuelActive'
                  : 'mapFuel'
              }
              style={mapFuelIconStyles}
            />
          </div>
        </>
      )}
      <div className="map-container">
        <GoogleMapReact
          map={mapRef}
          maps={mapsRef}
          // @ts-ignore
          bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_KEY }}
          apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
          defaultCenter={
            lastKnownLocation
              ? {
                  lat: lastKnownLocation.latitude,
                  lng: lastKnownLocation.longitude,
                }
              : getMapDefaultCenter(userAddress)
          }
          defaultZoom={9}
          options={{
            disableDefaultUI: true,
            fullscreenControl: false,
            zoomControl: false,
            showsPointsOfInterest: false,
            showsCompass: false,
            clickableIcons: false,
            keyboardShortcuts: false,
            gestureHandling: 'greedy',
            styles: mapStyles,
          }}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
          onChange={onBoundsChange}
          loadScriptExternally
          status="ready"
          loadingContent={
            <div className="map-loader">
              <Spin indicator={loadingIcon} />
            </div>
          }
        >
          {renderGasStations}
          {renderUnAvailableCars}
          {renderAvailableCars}
          {renderRentalAnnotation}
          {renderCallout}
          {renderSelectedCar}
        </GoogleMapReact>
      </div>
    </>
  );
};

export default React.memo(MapView);
