import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  addPayment,
  isKYCApp,
  LocalizePropType,
  selectAllowedSEPACountries,
  selectUser,
  withTextLocalizer,
} from 'getaway-data-layer';
import { useFormik } from 'formik';
import {
  GA_TITLES,
  isDashboardScreen,
  isIDVerificationRequired,
  navigateTo,
  reportGAEvent,
  showAlert,
} from '../../../../../utils';
import AddSepaView from './AddSepaView';
import { AddSepaModal, AddSepaSchema } from '../../../../../validation';

interface Values {
  name: string;
  iban: string;
}

type Props = {
  localize: LocalizePropType;
};

const AddSepaContainer = ({ localize }: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const allowedSEPACountries: any = useSelector(selectAllowedSEPACountries);
  const user: any = useSelector(selectUser);
  const supportedPayments = user?.supportedPaymentTypes || [];

  const [expanded, setExpanded] = useState(false);
  const [iban, setIban] = useState('');

  const onExpandPressed = (value: boolean) => {
    setExpanded(value);
  };

  const onSubmit = (values: Values) => {
    dispatch(
      addPayment(
        {
          sepa: {
            iban: values.iban.replace(/\s/g, ''),
            accountHolder: values.name.trim(),
          },
        },
        () => {
          if (isDashboardScreen()) {
            navigateTo(history, '/settings');
          } else {
            if (isIDVerificationRequired(user)) {
              navigateTo(history, `/onboarding/IDOnboarding`);
            } else {
              navigateTo(history, '/onboarding/DLOnboarding');
            }
          }
          reportGAEvent(GA_TITLES.ON_BOARDING.SEPA_SUBMITTED);
        }
      )
    );
  };

  const onClose = () => {
    if (
      isDashboardScreen() ||
      (supportedPayments?.length === 1 && user?.schufaCheckRequired)
    ) {
      history.goBack();
    } else {
      showAlert(
        '',
        localize('cancel.FirstSteps.dialog.message'),
        localize('cancel.FirstSteps.dialog.yes'),
        () => navigateTo(history, isKYCApp() ? '/settings' : '/map'),
        localize('cancel.FirstSteps.dialog.no'),
        () => {},
        true
      );
    }
  };

  const AddSepaFormik = useFormik({
    initialValues: AddSepaModal(user, '', ''),
    validationSchema: AddSepaSchema(
      localize,
      user,
      iban,
      false,
      allowedSEPACountries
    ),
    onSubmit,
  });

  const onIbanChanged = (e: any) => {
    const valueWithSpaces = e.target.value
      .toUpperCase()
      .replace(/[^\dA-Z]/g, '')
      .replace(/(.{4})/g, '$1 ')
      .trim();
    setIban(valueWithSpaces);
    AddSepaFormik.setFieldValue('iban', valueWithSpaces, true);
  };

  return (
    <>
      <AddSepaView
        localize={localize}
        user={user}
        formik={AddSepaFormik}
        supportedPayments={supportedPayments}
        onClose={onClose}
        expanded={expanded}
        onExpandPressed={onExpandPressed}
        onIbanChanged={onIbanChanged}
      />
    </>
  );
};

export default withTextLocalizer(AddSepaContainer);
