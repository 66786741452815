export const phoneNumberConstraint = () => ({
  phoneNumber: {
    presence: true,
    format: {
      pattern: /^[1-9][0-9]{8,11}$/,
      message(value: string) {
        if (value.length === 0) {
          return 'Bitte ausfüllen.';
        }
        return 'Bitte überprüfe deine Eingabe';
      },
    },
  },
});

export const CONSTRAINTS = {
  PHONE_NUMBER: phoneNumberConstraint,
};
