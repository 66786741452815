import { State } from '../@types';

export const selectAvailableCars = (store: State) =>
  store.carsSearch.availableCars;
export const selectUnavailableCars = (store: State) =>
  store.carsSearch.unavailableCars;
export const selectFrom = (store: State) => store.carsSearch.from;
export const selectBackby = (store: State) => store.carsSearch.backBy;
export const selectRegionChangeNo = (store: State) =>
  store.ui.map.regionChangeNo;
export const selectMapCenterLocation = (store: State) =>
  store.carsSearch.mapCenterLocation;
export const selectCircleId = (store: State) => store.carsSearch.circleId;
