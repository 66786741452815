import * as React from 'react';
import './PointItem.less';

type Props = {
  title: string;
  value?: string;
};

const PointItem = ({ title, value }: Props) => (
  <div className="point-item-container">
    <div className="point-item-row">
      <img alt="oval" src="/images/oval.png" className="point-item-oval" />
      <div className="point-item-title"> {title}</div>
    </div>
    {value !== '' && <div className="point-item-value">{value}</div>}
  </div>
);

PointItem.defaultProps = {
  value: '',
};

export default PointItem;
