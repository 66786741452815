import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  addPayment,
  addPaymentDetails,
  clearDialog,
  getPaymentData,
  getPaymentMethods,
  getSupportedCountriesCodesForCredit,
  isKYCApp,
  LocalizePropType,
  selectBrandSettings,
  selectUser,
  setActivePaymentData,
  withTextLocalizer,
} from 'getaway-data-layer';
import { differenceInMinutes } from 'date-fns';
import AdyenCheckout from '@adyen/adyen-web';
import '@adyen/adyen-web/dist/adyen.css';
import {
  isDashboardScreen,
  navigateTo,
  parsePaymentMethods,
  showAlert,
  getAdyenChcekoutConfigs,
  showPaypalAlert,
  isIDVerificationRequired,
  reportGAEvent,
  GA_TITLES,
} from '../../../../../utils';
import SelectPaymentView from './SelectPaymentView';

type Props = {
  localize: LocalizePropType;
};

const SelectPaymentContainer = ({ localize }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user: any = useSelector(selectUser);
  const brandSettings = useSelector(selectBrandSettings);
  const supportedPayments = user?.supportedPaymentTypes || [];
  const [hasCC, setHasCC] = React.useState(supportedPayments.includes('card'));
  const [isPayPalDialogShown, setIsPayPalDialogShown] = React.useState(false);
  const isSepaSupportedCountry = useMemo(
    () =>
      !user?.address?.country ||
      getSupportedCountriesCodesForCredit()
        .map((code) => code.toLowerCase())
        .includes(user?.address?.country?.toLowerCase()),
    [user?.address]
  );

  const onAddSepaPressed = () => {
    navigateTo(
      history,
      isDashboardScreen()
        ? '/dashboard/optimizedSepa'
        : '/onboarding/optimizedSepa'
    );
    reportGAEvent(GA_TITLES.ON_BOARDING.SELECT_SEPA);
  };

  const onPressNoCC = () => {
    setHasCC(false);
  };

  const onAddCCPressed = () => {
    if (isDashboardScreen()) {
      navigateTo(history, '/dashboard/addCC');
    } else {
      navigateTo(history, '/onboarding/addCC');
    }
    reportGAEvent(GA_TITLES.ON_BOARDING.SELECT_CC);
  };

  const onClose = () => {
    if (isDashboardScreen()) {
      navigateTo(history, '/settings');
    } else {
      showAlert(
        '',
        localize('cancel.FirstSteps.dialog.message'),
        localize('cancel.FirstSteps.dialog.yes'),
        () => navigateTo(history, isKYCApp() ? '/settings' : '/map'),
        localize('cancel.FirstSteps.dialog.no'),
        () => {},
        true
      );
    }
  };

  const onAddPayInPressed = () => {
    const successCallback = (user) => {
      if (isDashboardScreen()) {
        navigateTo(history, '/settings');
      } else {
        if (isIDVerificationRequired(user)) {
          navigateTo(history, `/onboarding/IDOnboarding`);
        } else {
          navigateTo(history, '/onboarding/DLOnboarding');
        }
      }
      reportGAEvent(GA_TITLES.ON_BOARDING.SEPA_SUBMITTED);
    };
    dispatch(
      addPayment(
        {
          // @ts-ignore
          payIn: {},
        },
        (data) => {
          if (brandSettings?.payInOnboardingType === 'idDocument') {
            if (['open', 'verified'].includes(user?.identityDocument?.status)) {
              navigateTo(history, '/settings');
            } else {
              navigateTo(
                history,
                isDashboardScreen()
                  ? '/dashboard/IDOnboarding'
                  : '/onboarding/IDOnboarding'
              );
            }
          } else if (brandSettings?.payInOnboardingType === 'schufaCheck') {
            if (user.schufaCheckRequired) {
              navigateTo(
                history,
                isDashboardScreen()
                  ? '/dashboard/schufaCheck'
                  : '/onboarding/schufaCheck'
              );
            } else {
              successCallback(data);
            }
          } else {
            successCallback(data);
          }
        }
      )
    );
  };

  const onSubmitDropinPayment = (state: any, action: any) => {
    // submit CC
    if (state?.data?.paymentMethod?.holderName) {
      dispatch(
        addPayment(
          {
            card: state.data.paymentMethod,
          },
          () => {
            if (isDashboardScreen()) {
              navigateTo(history, '/settings');
            } else {
              if (isIDVerificationRequired(user)) {
                navigateTo(history, `/onboarding/IDOnboarding`);
              } else {
                navigateTo(history, '/onboarding/DLOnboarding');
              }
            }
            reportGAEvent(GA_TITLES.ON_BOARDING.CC_SUBMITTED);
          }
        )
      );
    } else {
      // submit paypal
      dispatch(
        addPayment(
          {
            // @ts-ignore
            paypal: { any: 'any' },
          },
          (user) => {
            setTimeout(() => {
              action.handleAction(user.paymentMethods[0].action);
            }, 400);
          }
        )
      );
    }
  };

  const onSubmitDropinPaymentDetails = (state: any, dropin: any) => {
    dispatch(
      addPaymentDetails(state.data, (response) => {
        if (response.action) {
          // Drop-in handles the action object from the /payments response
          dropin.handleAction(response.action);
        } else {
          // Your function to show the final result to the shopper
          dropin.setStatus('success');
          dispatch(clearDialog());
          if (isDashboardScreen()) {
            navigateTo(history, '/settings');
          } else {
            if (isIDVerificationRequired(user)) {
              navigateTo(history, `/onboarding/IDOnboarding`);
            } else {
              navigateTo(history, '/onboarding/DLOnboarding');
            }
            reportGAEvent(GA_TITLES.ON_BOARDING.PAYPAL_SUBMITTED);
          }
        }
      })
    );
  };

  const onClickPaypal = () => {
    setIsPayPalDialogShown(true);
    showPaypalAlert(
      localize('payment.paypal.block.title'),
      localize('payment.paypal.block.description'),
      () => <div className="paypal-button" id="paypal-div" />,
      localize('cancel'),
      () => {
        setIsPayPalDialogShown(false);
      }
    );
    reportGAEvent(GA_TITLES.ON_BOARDING.SELECT_PAYPAL);
  };

  const initializeAdyenDropIn = async (paymentMethods: any) => {
    // Create an instance of AdyenCheckout using the configuration object.
    // @ts-ignore
    const checkout = new AdyenCheckout(
      getAdyenChcekoutConfigs(
        user.locale,
        user.address?.country || brandSettings?.defaultCountryCode,
        brandSettings?.adyenClientKey!,
        paymentMethods,
        onSubmitDropinPayment,
        onSubmitDropinPaymentDetails
      )
    );

    const dropin = checkout.create('paypal').mount('#paypal-div');
  };

  useEffect(() => {
    if (
      user?.paymentMethods?.length > 0 &&
      user?.paymentMethods[0].status !== 'verified'
    ) {
      if (user?.paymentMethods[0]?.paymentRequestId) {
        dispatch(
          getPaymentData(
            user?.paymentMethods[0]?.paymentRequestId,
            (data: any) => {
              if (
                data?.status === 'confirmed' ||
                differenceInMinutes(new Date(), new Date(data?.created)) > 5
              ) {
                dispatch(setActivePaymentData(null));
              }
            }
          )
        );
      }
    } else {
      dispatch(setActivePaymentData(null));
    }

    if (isPayPalDialogShown) {
      setTimeout(() => {
        dispatch(
          getPaymentMethods((paymentMethods) => {
            initializeAdyenDropIn(parsePaymentMethods(paymentMethods));
          })
        );
      }, 50);
    }
  }, [isPayPalDialogShown]);

  return (
    <>
      <SelectPaymentView
        localize={localize}
        hasCC={hasCC}
        onPressNoCC={onPressNoCC}
        isSepaSupportedCountry={isSepaSupportedCountry}
        supportedPaymentTypes={user?.supportedPaymentTypes}
        onClose={onClose}
        onAddSepaPressed={onAddSepaPressed}
        onAddCCPressed={onAddCCPressed}
        onClickPaypal={onClickPaypal}
        onAddPayInPressed={onAddPayInPressed}
      />
    </>
  );
};

export default withTextLocalizer(SelectPaymentContainer);
