import React from 'react';
import './ServiceItem.less';
import { abbreviateText, LocalizePropType } from 'getaway-data-layer';
import ExpandableItem from '../ExpandableItem/ExpandableItem';
import LabeledListItem from '../LabeledListItem/LabeledListItem';
import ListItem from '../ListItem/ListItem';
import { format, parseISO } from 'date-fns';
import Card from '../Card/Card';

type Props = {
  localize: LocalizePropType;
  logo: string;
  title: string;
  background: string;
  description: string;
  provider: string;
  customerNr: string;
  PIN: string;
  onClickLogin: () => void;
  onClickImprint: () => void;
  onClickDataProtection: () => void;
  onClickAGB: () => void;
  verified: boolean;
  timestamp: string;
  note?: string;
  tariff?: string;
  email?: string;
  paymentAccount?: string;
  tariffPartnerLink?: string;
  sepa?: string;
};

function formatDate(dateString) {
  if (dateString) {
    const date = parseISO(dateString);
    return format(date, "dd.MM.yyyy, HH:mm 'Uhr'");
  } else {
    return null;
  }
}

const ServiceItem = ({
  localize,
  logo,
  background,
  title,
  description,
  provider,
  customerNr,
  PIN,
  onClickLogin,
  onClickImprint,
  onClickDataProtection,
  onClickAGB,
  verified,
  timestamp,
  note,
  tariff,
  email,
  paymentAccount,
  tariffPartnerLink,
  sepa,
}: Props) => {
  return (
    <Card>
      <div className="service-bg">
        <img className="service-bg-img" src={background} />
        <img className="service-img" src={logo} />
      </div>
      <div className="service-title">{title}</div>
      <div className="service-items">
        <ExpandableItem
          title={localize('service.account')}
          titleClassName="service-items-title"
          containerStyles={{ paddingRight: 0, paddingLeft: 0 }}
          value={
            <div className={`service-status${verified ? '-verified' : ''}`}>
              {localize(`service.status.${verified ? 'unlocked' : 'locked'}`)}
            </div>
          }
        >
          {!verified && (
            <div className="service-status-note">
              <div className="service-status-msg">
                {note || localize('service.status.locked.note')}
              </div>
            </div>
          )}
          {!note && (
            <ListItem
              containerStyles={{ padding: '0px 8px 10px 8px' }}
              title={localize('service.tariff')}
              value={tariff}
              valueStyles={{ color: '#000211', fontSize: 15 }}
              greyTextColor
            />
          )}
          {!note && paymentAccount && (
            <ListItem
              containerStyles={{ padding: '0px 8px 10px 8px' }}
              title={localize('service.payment')}
              value={paymentAccount}
              customCopyValue={sepa}
              valueStyles={{ color: '#000211', fontSize: 15 }}
              greyTextColor
            />
          )}
          {!note && (
            <ListItem
              containerStyles={{ padding: '0px 8px 10px 8px' }}
              title={localize('service.email')}
              value={abbreviateText(email, 25)}
              valueStyles={{ color: '#000211', fontSize: 15 }}
              greyTextColor
              showSeparator={!!tariffPartnerLink}
            />
          )}
          {tariffPartnerLink && (
            <ListItem
              containerStyles={{ padding: '0px 8px 10px 8px' }}
              title={localize('service.tariff.partner')}
              titleStyles={{ color: '#000211', fontSize: 15 }}
              onClick={() => window.open(tariffPartnerLink, '_blank')}
              rightIcon="arrowRight"
              rightIconSize={12}
              showSeparator={false}
            />
          )}
        </ExpandableItem>

        <ExpandableItem
          title={localize('service.login.section')}
          titleClassName="service-items-title"
          containerStyles={{ paddingRight: 0, paddingLeft: 0 }}
        >
          <ListItem
            containerStyles={{ padding: '0px 8px 10px 8px' }}
            title={localize('service.customer.number')}
            value={customerNr}
            valueStyles={{ color: '#000211', fontSize: 15 }}
            greyTextColor
            enableCopyOnClick={customerNr !== './.'}
          />
          <ListItem
            containerStyles={{ padding: '0px 8px 10px 8px' }}
            title={localize('service.customer.pin')}
            value={PIN}
            valueStyles={{ color: '#000211', fontSize: 15 }}
            greyTextColor
            enableCopyOnClick={PIN !== './.'}
          />
          <ListItem
            containerStyles={{ padding: '0px 8px 10px 8px' }}
            title={localize('service.login')}
            titleStyles={{ fontWeight: '600 !important' }}
            onClick={onClickLogin}
            brandTextColor
            showSeparator={false}
          />
        </ExpandableItem>

        <ExpandableItem
          title={localize('service.info')}
          titleClassName="service-items-title"
          containerStyles={{ paddingRight: 0, paddingLeft: 0 }}
          value={''}
          showSeparator={false}
        >
          <LabeledListItem
            label={localize('service.info.desc')}
            value={description}
          />
          <LabeledListItem
            label={localize('service.info.provider')}
            value={provider}
          />
          <div className="service-legal">
            <div className="service-legal-link" onClick={onClickImprint}>
              {localize('service.imprint')}
            </div>
            |
            <div className="service-legal-link" onClick={onClickDataProtection}>
              {localize('service.data')}
            </div>
            |
            <div className="service-legal-link" onClick={onClickAGB}>
              {localize('service.agb')}
            </div>
          </div>
        </ExpandableItem>
      </div>
    </Card>
  );
};

export default ServiceItem;
