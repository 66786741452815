/* global google */
import React, { useState } from 'react';
import Modal from 'react-modal';
import PlacesAutocomplete, {
  geocodeByPlaceId,
} from 'react-places-autocomplete';
import './AddressSearchModal.less';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { LocalizePropType, withTextLocalizer } from 'getaway-data-layer';

const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

type Props = {
  localize: LocalizePropType;
  isModalOpen: boolean;
  onCloseModal: () => void;
  countryRestriction: string;
  onLocationSelected: (location: any) => void;
};

const AddressAutoCompleteModal = ({
  localize,
  isModalOpen,
  onCloseModal,
  countryRestriction,
  onLocationSelected,
}: Props) => {
  const [searchInput, setSearchInput] = useState('');

  const getAddressDetails = (details: Record<string, any>): any => {
    const { address_components: addressComponents } = details;
    const address = {
      streetName: '',
      streetNumber: '',
      city: '',
      country: '',
      postalCode: '',
    };

    if (addressComponents) {
      addressComponents.forEach(
        (component: { types: any[]; short_name: string }) => {
          switch (component.types[0]) {
            case 'street_number':
              address.streetNumber = component.short_name;
              break;

            case 'route':
              address.streetName = component.short_name;
              break;

            case 'locality':
              address.city = component.short_name;
              break;

            case 'country':
              address.country = component.short_name;
              break;

            case 'postal_code':
              address.postalCode = component.short_name;
              break;

            default:
              break;
          }
        }
      );
    }
    onLocationSelected(address);
  };

  const onPlaceSelected = (address: any, placeID) => {
    if (isModalOpen) {
      var map = new google.maps.Map(document.getElementById('map'), {
        center: { lat: -33.866, lng: 151.196 },
        zoom: 15,
      });
      var service = new google.maps.places.PlacesService(map);
      service.getDetails(
        {
          placeId: placeID,
        },
        function (place, status) {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            getAddressDetails(place);
          }
        }
      );
    }
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={onCloseModal}
      className="auto-complete-modal"
      overlayClassName="auto-complete-overlay"
    >
      <PlacesAutocomplete
        value={searchInput}
        onChange={(value: any) => setSearchInput(value)}
        onSelect={onPlaceSelected}
        debounce={200}
        searchOptions={{
          componentRestrictions: { country: countryRestriction },
          types: ['address'],
        }}
      >
        {({
          getInputProps,
          suggestions,
          getSuggestionItemProps,
          loading,
        }: any) => (
          <>
            <div className="auto-complete-header">
              <input
                autoFocus
                {...getInputProps({
                  placeholder: 'Adresse eingeben ...',
                  className: 'auto-complete-input',
                })}
              />
              <div className="auto-complete-close" onClick={onCloseModal}>
                {localize('addressSearch.cancel.text')}
              </div>
            </div>
            <div className="autocomplete-dropdown-container">
              {loading && (
                <div className="auto-complete-loader">
                  <Spin indicator={loadingIcon} />
                </div>
              )}
              {suggestions.map((suggestion: any, index: number) => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                    })}
                    key={`${suggestion} ${index}`}
                  >
                    <span>{`${suggestion?.formattedSuggestion?.mainText} ${
                      suggestion?.formattedSuggestion?.secondaryText?.split(
                        ','
                      )[0]
                    }`}</span>
                    <div className="auto-complete-separator" />
                  </div>
                );
              })}
              {suggestions.length > 0 && (
                <img
                  src="/images/google.png"
                  alt="powered by Google"
                  style={{
                    width: 120,
                    height: 40,
                    objectFit: 'contain',
                    alignSelf: 'center',
                  }}
                />
              )}
            </div>
          </>
        )}
      </PlacesAutocomplete>
      <div id="map" />
    </Modal>
  );
};

export default withTextLocalizer(AddressAutoCompleteModal);
