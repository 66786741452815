import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import './global.less';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
// @ts-ignore
import { PersistGate } from 'redux-persist/integration/react';
import packageJson from '../package.json';
import reportWebVitals from './reportWebVitals';
import { store, persistor } from './redux/config';
import AppRoute from './routes/AppRoute';
import { Data, Setup } from './init/index';
import LoadingContainer from './ui/components/LoadingScreen/LoadingContainer';
import Notification from './ui/components/Notification/Notification';
import Dialog from './ui/components/Dialog/Dialog';
import Subscriber from './init/Subscriber';
import StatusIndicator from './ui/components/StatusIndicator/StatusIndicator';
import ErrorBoundary from './ui/components/ErrorBoundary/ErrorBoundary';
import MapContainer from './ui/pages/Map/MapContainer';
import { isMobileBrowser } from './utils';
import { isBND, isCTF, isFRM, isKYCApp, isTLA } from 'getaway-data-layer';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.2,
  release: packageJson.version,
  environment:
    process.env.REACT_APP_STAGE === 'production' ? 'production' : 'staging',
  ignoreErrors: [
    'TypeError: Load failed',
    'URI error',
    'URI sequence',
    'URI malformed',
  ],
});

const rootElement = document.getElementById('root');
if (rootElement) {
  let root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );

  root.render(
    <React.Fragment>
      <Provider store={store}>
        <PersistGate loading={<LoadingContainer />} persistor={persistor}>
          <ErrorBoundary>
            <Setup>
              <Subscriber>
                <Data>
                  <>
                    <StatusIndicator />
                    <Dialog />
                    <Notification />
                    {isMobileBrowser() || isKYCApp() ? (
                      <div
                        className={
                          !isMobileBrowser()
                            ? isBND()
                              ? 'bnd-desktop-bg'
                              : isFRM()
                              ? 'frm-desktop-bg'
                              : isTLA()
                              ? 'tla-desktop-bg'
                              : isCTF()
                              ? 'ctf-desktop-bg'
                              : 'sps-desktop-bg'
                            : ''
                        }
                      >
                        <Router>
                          <AppRoute />
                        </Router>
                      </div>
                    ) : (
                      <Router>
                        <div style={{ display: 'flex' }}>
                          <AppRoute />
                          <div
                            style={{
                              position: 'relative',
                              height: '100%',
                              width: 'calc(100%)',
                            }}
                          >
                            <MapContainer />
                          </div>
                        </div>
                      </Router>
                    )}
                  </>
                </Data>
              </Subscriber>
            </Setup>
          </ErrorBoundary>
        </PersistGate>
      </Provider>
    </React.Fragment>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
