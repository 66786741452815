const dev = {
  api: {
    baseURL: 'https://api.staging.get-a-way.com',
    apiVersion: '1.1.23',
  },
  brand: {
    xBrandId: process.env.REACT_APP_X_BRAND_ID,
  },
};

const prod = {
  api: {
    baseURL: 'https://api.get-a-way.com',
    apiVersion: '1.1.23',
  },
  brand: {
    xBrandId: process.env.REACT_APP_X_BRAND_ID,
  },
};

const config = process.env.REACT_APP_STAGE === 'production' ? prod : dev;

export default {
  ...config,
};
