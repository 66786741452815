import React, { useEffect, useState } from 'react';
import { LocalizePropType, uploadDocument } from 'getaway-data-layer';
import { useFilePicker } from 'use-file-picker';
import { useDispatch } from 'react-redux';
import ListItem from '../ListItem/ListItem';

type Props = {
  localize: LocalizePropType;
};

const FileUploadItem = ({ localize }: Props) => {
  const dispatch = useDispatch();
  const [uploading, setUploading] = useState(false);
  const [openFileSelector, { filesContent, loading }] = useFilePicker({
    readAs: 'DataURL',
    accept: 'image/*',
    multiple: false,
    limitFilesConfig: { max: 1 },
    maxFileSize: 50,
  });

  useEffect(() => {
    if (filesContent?.[0]?.content) {
      setUploading(true);
      dispatch(
        uploadDocument(filesContent?.[0]?.content, 'OTHER', () =>
          setUploading(false)
        )
      );
    }
  }, [filesContent]);

  return (
    <ListItem
      title={localize('settings.uploadDoc')}
      leftItem={
        <img
          style={{
            width: 20,
            height: 20,
            marginRight: 35,
            objectFit: 'contain',
          }}
          src="/images/upload.png"
          alt="user"
        />
      }
      rightIcon="arrowRight"
      rightIconSize={14}
      onClick={() => {
        openFileSelector();
      }}
      isLoading={loading || uploading}
    />
  );
};

FileUploadItem.defaultProps = {};

export default FileUploadItem;
