import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  addPayment,
  getUser,
  LocalizePropType,
  schufaCheck,
  selectUser,
  withTextLocalizer,
  setBDay,
  selectDateOfBirth,
  selectIban,
  setIban,
  isKYCApp,
  setNotification,
  APP,
  selectBicMandatory,
  UserDataOutput,
  selectAllowedSEPACountries,
} from 'getaway-data-layer';
import { useFormik } from 'formik';
import {
  GA_TITLES,
  getAddressStatus,
  isDashboardScreen,
  isIDVerificationRequired,
  navigateTo,
  reportGAEvent,
  showAlert,
} from '../../../../../utils';
import OptimizedSepaView from './OptimizedSepaView';
import {
  AddSepaModal,
  AddSepaSchema,
  maxBirthDate,
  minBirthDate,
} from '../../../../../validation';
import { format, isValid } from 'date-fns';
import { generateDepositPaymentLink } from 'getaway-data-layer/src/redux/store/userData/user/actions';

interface Values {
  iban: string;
  bic: string;
  dateOfBirth: any;
  firstName: string;
  lastName: string;
}

type Props = {
  localize: LocalizePropType;
};

const USER_UPDATER_DURATION = 10000;

const OptimizedSepaContainer = ({ localize }: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const user: any = useSelector(selectUser);
  const reduxDateOfBirth: any = useSelector(selectDateOfBirth);
  const iban: any = useSelector(selectIban);
  const bicMandatory: any = useSelector(selectBicMandatory);
  const allowedSEPACountries: any = useSelector(selectAllowedSEPACountries);
  const addressStatus = getAddressStatus(user);
  const dateFormat = 'dd/MM/yyyy';

  const [expanded, setExpanded] = useState(false);
  const [customError, setCustomError] = useState('') as any;

  const onExpandPressed = (value: boolean) => {
    setExpanded(value);
  };

  const onSuccess = (user = null) => {
    if (user?.deposit?.toPayForSepaUnlock !== undefined && !user?.blocked) {
      const newWindow = window.open('', '_blank');
      navigateTo(history, '/settings');
      dispatch(
        generateDepositPaymentLink(
          user?.deposit?.toPayForSepaUnlock,
          `${window.location.origin}/settings`,
          (paymentLink: string) => {
            if (newWindow) {
              // Redirect the new window to the payment link
              newWindow.location.href = paymentLink;
            }
          }
        )
      );
    } else if (isDashboardScreen() || isKYCApp()) {
      navigateTo(history, '/settings');
    } else {
      if (isIDVerificationRequired(user)) {
        navigateTo(history, `/onboarding/IDOnboarding`);
      } else {
        navigateTo(history, '/onboarding/DLOnboarding');
      }
    }
    reportGAEvent(GA_TITLES.ON_BOARDING.SEPA_SUBMITTED);
  };

  const onSubmit = (values: Values) => {
    const formattedBirth = values?.dateOfBirth
      ? format(new Date(values.dateOfBirth), 'yyyy-MM-dd')
      : null;
    const sepa = {
      iban: values.iban.replace(/\s/g, ''),
      accountHolder: `${values.firstName.trim()} ${values.lastName.trim()}`,
      bic: values.bic.replace(/\s/g, ''),
    };
    if (user.creditworthinessApproved) {
      dispatch(
        addPayment(
          {
            sepa,
          },
          () => {
            onSuccess();
          }
        )
      );
    } else if (user?.address?.streetName) {
      dispatch(
        schufaCheck(
          {
            firstName: values.firstName.trim(),
            lastName: values.lastName.trim(),
            ...(formattedBirth && { dateOfBirth: formattedBirth.trim() }),
            address: {
              streetName: user.address?.streetName?.trim(),
              streetNumber: user.address?.streetNumber?.trim(),
              city: user.address?.city?.trim(),
              postalCode: user.address?.postalCode?.trim(),
              country: user.address?.country?.trim(),
            },
          },
          (user: UserDataOutput) => {
            dispatch(
              addPayment({
                sepa,
              })
            );
            onSuccess(user);
            reportGAEvent(GA_TITLES.ON_BOARDING.SCHUFA_SUBMITTED);
          },
          () => {
            dispatch(
              addPayment({
                sepa,
              })
            );
            onSuccess();
          }
        )
      );
    } else {
      dispatch(
        setNotification({
          message: 'validation.address.street.invalid',
          type: APP.NOTIFICATION_TYPE.ERROR,
        })
      );
    }
  };

  const onClose = () => {
    if (isDashboardScreen() || !isKYCApp()) {
      history.goBack();
    } else {
      showAlert(
        '',
        localize('cancel.FirstSteps.dialog.message'),
        localize('cancel.FirstSteps.dialog.yes'),
        () => navigateTo(history, isKYCApp() ? '/settings' : '/map'),
        localize('cancel.FirstSteps.dialog.no'),
        () => {},
        true
      );
    }
  };

  const onPressAddress = () => {
    navigateTo(history, '/dashboard/addAddress');
  };

  const onPressAddressInProgress = () => {
    navigateTo(history, '/dashboard/addAddress');
  };

  const onPressAddressCompleted = () => {};

  const AddSepaFormik = useFormik({
    initialValues: AddSepaModal(user, reduxDateOfBirth, iban),
    validationSchema: AddSepaSchema(
      localize,
      user,
      iban,
      bicMandatory,
      allowedSEPACountries
    ),
    onSubmit,
  });

  const onIbanChanged = (e: any) => {
    const valueWithSpaces = e.target.value
      .toUpperCase()
      .replace(/[^\dA-Z]/g, '')
      .replace(/(.{4})/g, '$1 ')
      .trim();
    dispatch(setIban(valueWithSpaces));
    AddSepaFormik.setFieldValue('iban', valueWithSpaces, true);
  };

  useEffect(() => {
    if (isValid(AddSepaFormik.values.dateOfBirth))
      dispatch(setBDay(AddSepaFormik?.values?.dateOfBirth));
  }, [AddSepaFormik.values.dateOfBirth]);

  useEffect(() => {
    let userUpdaterId;
    if (isDashboardScreen()) {
      dispatch(getUser());
    } else {
      dispatch(getUser());
      userUpdaterId = setInterval(() => {
        dispatch(getUser());
      }, USER_UPDATER_DURATION);
    }
    return () => {
      if (userUpdaterId) clearInterval(userUpdaterId);
    };
  }, []);

  return (
    <>
      <OptimizedSepaView
        localize={localize}
        user={user}
        customError={customError || AddSepaFormik.errors.dateOfBirth}
        formik={AddSepaFormik}
        onClose={onClose}
        dateFormat={dateFormat}
        minDate={minBirthDate}
        maxDate={maxBirthDate}
        expanded={expanded}
        addressStatus={addressStatus}
        address={user?.address}
        bicMandatory={bicMandatory}
        onExpandPressed={onExpandPressed}
        onIbanChanged={onIbanChanged}
        onPressAddress={onPressAddress}
        onPressAddressInProgress={onPressAddressInProgress}
        onPressAddressCompleted={onPressAddressCompleted}
      />
    </>
  );
};

export default withTextLocalizer(OptimizedSepaContainer);
