import React from 'react';
import './MapFilterBar.less';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectMapContent,
  selectUser,
  selectIsCarOpened,
  withTextLocalizer,
  selectSelectedCar,
  reserveCar,
  msToHMS,
  onReserveCarClicked,
  isRentalCard,
  selectFrom,
  selectBackby,
  timeStringFromDate,
  dateAndTimeStringFromDate,
  datesAreOnSameDay,
  setOpenedModal,
  selectUnavailableCars,
  selectIsReservingCar,
  selectIsBookingCar,
  selectLastKnownLocation,
  selectMapCenterLocation,
  findNearestAvailableCar,
  selectMapBoundaries,
  isInsidePolygon,
  selectBrandSettings,
  setSelectedCar,
  setShowHomeWT,
  setNotification,
  selectDevicePaired,
  selectFeatures,
  sendVerificationCode,
  isPaymentVerified,
  submitIdentityShopper,
  submitChallenge,
  setRecoverPaymentAuth,
  setFrom,
  setBackBy,
  selectIsOBI,
  selectIsExpert,
  selectIsSPP,
  setPreSignupBooking,
  selectAvailableCars,
} from 'getaway-data-layer';
import { addMinutes, addHours, isToday } from 'date-fns';
import {
  GA_TITLES,
  getCurrentPosition,
  isLocationPermissionGranted,
  isMobileBrowser,
  navigateTo,
} from '../../../utils';
import BrandButton from '../BrandButton/BrandButton';
import { useCustomerSupport } from '../../../hooks/useCustomerSupport';
import { handleRentalViolations } from 'getaway-data-layer/src/helpers/rentalsViolationsHelpers';

type Props = {
  localize: any;
};

const MapFilterBar = ({ localize }: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const devicePaired = useSelector(selectDevicePaired);
  const features = useSelector(selectFeatures);
  const { helpAction } = useCustomerSupport(localize);
  const isCarOpened = useSelector(selectIsCarOpened) as any;
  const mapContent = useSelector(selectMapContent) as any;
  const selectedCar = useSelector(selectSelectedCar) as any;
  const from = useSelector(selectFrom) as any;
  const backBy = useSelector(selectBackby) as any;
  const brandSettings = useSelector(selectBrandSettings) as any;
  const unAvailableCars = Object.keys(useSelector(selectUnavailableCars));
  const reservingCar = useSelector(selectIsReservingCar) as boolean;
  const bookingCar = useSelector(selectIsBookingCar) as boolean;
  const lastKnownLocation = useSelector(selectLastKnownLocation) as any;
  const mapCenterLocation = useSelector(selectMapCenterLocation) as any;
  const mapBoundaries = useSelector(selectMapBoundaries) as Array<any>;
  const isOBI = useSelector(selectIsOBI);
  const isExpert = useSelector(selectIsExpert);
  const isSPP = useSelector(selectIsSPP);
  const availableCars = useSelector(selectAvailableCars);

  let color = null;

  if (isOBI) {
    color = '#ff7e21';
  } else if (isExpert) {
    color = '#FF5E19';
  } else if (isSPP) {
    color = '#f3be19';
  }

  const startDate = from ? new Date(from) : null;
  const backByDate = backBy ? new Date(backBy) : null;
  const formattedFrom = startDate
    ? isToday(startDate)
      ? `${localize('today')}, ${timeStringFromDate(startDate)}`
      : dateAndTimeStringFromDate(startDate, localize)
    : localize('now');
  const formattedBackBy = backByDate
    ? isToday(backByDate)
      ? `${timeStringFromDate(backByDate)} Uhr`
      : datesAreOnSameDay(backByDate, startDate)
      ? timeStringFromDate(backByDate)
      : dateAndTimeStringFromDate(backByDate, localize)
    : '';

  const disabledBar = unAvailableCars.includes(mapContent?.id);

  const onPressMapFilter = () => {
    dispatch(setSelectedCar(null));
    dispatch(setOpenedModal('MapFilterModal'));
  };

  const onReserveCar = () => {
    if (
      brandSettings?.onboardingType === 'onBooking' &&
      user?.challenges &&
      user?.challenges[0].achieved === 1
    ) {
      navigateTo(history, '/onboarding/addPhone');
    } else {
      if (!isMobileBrowser() && !from) {
        dispatch(setFrom(new Date(addMinutes(new Date(), 11))));
        dispatch(setBackBy(new Date(addHours(new Date(), 4))));
      }
      onReserveCarClicked(
        localize,
        from
          ? new Date(from)
          : !isMobileBrowser()
          ? new Date(addMinutes(new Date(), 11))
          : null,
        selectedCar?.availableUntil,
        msToHMS as any,
        user!!,
        false,
        false,
        selectedCar?.status !== 'BLOCKED',
        () => {
          dispatch(setPreSignupBooking(null));
          navigateTo(history, '/booking/confirmation');
        },
        (service: boolean) => {
          dispatch(
            reserveCar(
              localize,
              selectedCar,
              service,
              () => {
                // RentalWalkThrough
              },
              null,
              (violations: any) =>
                handleRentalViolations(
                  violations,
                  user,
                  localize,
                  setNotification,
                  devicePaired,
                  null,
                  features,
                  sendVerificationCode,
                  helpAction,
                  isPaymentVerified,
                  submitIdentityShopper,
                  submitChallenge,
                  setRecoverPaymentAuth,
                  dispatch,
                  history
                )
            )
          );
        }
      );
    }
  };

  const openConfirmBooking = () => {
    dispatch(setShowHomeWT(false));
    if (!from) {
      dispatch(setFrom(new Date(addMinutes(new Date(), 30))));
      dispatch(setBackBy(new Date(addHours(new Date(), 3))));
    }
    navigateTo(history, '/booking/confirmation');
  };

  const openSignup = () => {
    navigateTo(history, '/signup');
  };

  const onFindCarClicked = () => {
    console.log('onClickFindCarClicked !!!!');
    const refLocation = isInsidePolygon(lastKnownLocation, mapBoundaries)
      ? lastKnownLocation
      : mapCenterLocation;
    dispatch(
      findNearestAvailableCar(
        refLocation,
        1000,
        (action: any) => {
          isLocationPermissionGranted().then((granted) => {
            if (granted) {
              action();
            } else {
              getCurrentPosition({
                onSuccess: () => {
                  action();
                },
              });
            }
          });
        },
        true
      )
    );
  };

  return (
    !['gasStation'].includes(mapContent.mapType) &&
    !isRentalCard(mapContent.cardType) &&
    !isCarOpened && (
      <div className="map-filter-bar-container">
        <img
          className="map-filter-bar-gradiant"
          src="/images/gradientBackground50.png"
          alt="gradiant"
        />
        <div className="map-filter-bar-time-button" onClick={onPressMapFilter}>
          {!backBy ? (
            <div>
              <div className="map-filter-bar-mainText">
                {localize('map.filter.now')}
              </div>
              <div className="map-filter-bar-secondaryText" style={{ color }}>
                {localize('map.filter.secondary.text')}
              </div>
            </div>
          ) : (
            <div>
              <div className="map-filter-bar-boldText">{formattedFrom}</div>
              <div className="map-filter-bar-row">
                <div className="map-filter-bar-lightText">{`${localize(
                  'map.filter.time.to'
                )} ${formattedBackBy}`}</div>
              </div>
            </div>
          )}
        </div>

        <BrandButton
          id={
            disabledBar
              ? GA_TITLES.RENTAL.RESERVE_DISABLED
              : selectedCar
              ? GA_TITLES.RENTAL.RESERVE
              : GA_TITLES.RENTAL.FIND_NEAREST
          }
          title={localize(
            disabledBar
              ? 'reserve.btn.unavailable'
              : selectedCar
              ? backBy
                ? 'booking.driver.offer.choose'
                : 'booking.driver.offer.reserve'
              : 'map.filter.findCar'
          )}
          containerStyles={{ width: `${window.innerWidth * 0.39}px` }}
          classStyle={
            selectedCar
              ? 'map-filter-bar-button '
              : 'map-filter-bar-button map-filter-small'
          }
          onClick={() => {
            if (!selectedCar) {
              // 1. If no car is selected, trigger a search for available cars
              return onFindCarClicked();
            } else if (!user) {
              // 2. If a car is selected but no user is logged in, prompt for signup
              return openConfirmBooking();
            } else {
              // 3. If both a car is selected and a user is logged in, proceed to reserve the car
              return onReserveCar();
            }
          }}
          disabled={disabledBar || reservingCar || bookingCar}
        />
      </div>
    )
  );
};

export default withTextLocalizer(MapFilterBar);
