import React from 'react';
import './SelectPaymentView.less';
import NavigationBar from '../../../../components/NavigationBar/NavigationBar';
import ListItem from '../../../../components/ListItem/ListItem';
import { isDashboardScreen } from '../../../../../utils';
import { isLocalizeAdded, LocalizePropType } from 'getaway-data-layer';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

type Props = {
  localize: LocalizePropType;
  supportedPaymentTypes: any;
  isSepaSupportedCountry: boolean;
  onClose: () => void;
  onAddSepaPressed: () => void;
  onAddCCPressed: () => void;
  hasCC: boolean;
  onPressNoCC: () => void;
  onClickPaypal: () => void;
  onAddPayInPressed: () => void;
};

const SelectPaymentView = ({
  localize,
  isSepaSupportedCountry,
  supportedPaymentTypes,
  hasCC,
  onClose,
  onAddSepaPressed,
  onAddCCPressed,
  onPressNoCC,
  onClickPaypal,
  onAddPayInPressed,
}: Props) => {
  return (
    <div className="screen-container">
      <NavigationBar
        title={localize('selectPayment.title')}
        description={localize('selectPayment.desr')}
        onClose={onClose}
        showHelp
        showArrowBack={isDashboardScreen()}
      />
      <div
        className={
          isLocalizeAdded(localize, 'payment.select.faq')
            ? 'screen-body-faq'
            : 'screen-body'
        }
      >
        <div
          className={
            isLocalizeAdded(localize, 'payment.select.faq')
              ? 'screen-body-content-faq'
              : 'screen-body-content'
          }
        >
          {supportedPaymentTypes.includes('card') && (
            <ListItem
              title={localize('settings.addPaymentMethod.visa')}
              brandTextColor
              leftIcon="visa"
              rightIcon="arrowRight"
              leftIconSize={25}
              rightIconSize={14}
              onClick={onAddCCPressed}
            />
          )}
          {hasCC && (
            <ListItem
              title={localize('user.selectPayment.noCC')}
              brandTextColor
              rightIcon="arrowRight"
              leftIconSize={25}
              rightIconSize={14}
              onClick={onPressNoCC}
            />
          )}
          {!hasCC && (
            <>
              {supportedPaymentTypes.includes('paypal') && (
                <div className="paypal-container">
                  <ListItem
                    title={localize('settings.addPaymentMethod.paypal')}
                    brandTextColor
                    leftIcon="paypal"
                    rightIcon="arrowRight"
                    leftIconSize={26}
                    rightIconSize={14}
                    onClick={onClickPaypal}
                  />
                </div>
              )}
              {supportedPaymentTypes.includes('sepa') &&
                isSepaSupportedCountry && (
                  <ListItem
                    title={localize('settings.addPaymentMethod.sepa')}
                    brandTextColor
                    leftIcon="sepa"
                    rightIcon="arrowRight"
                    leftIconSize={25}
                    rightIconSize={14}
                    onClick={onAddSepaPressed}
                  />
                )}
              {supportedPaymentTypes.includes('payIn') && (
                <ListItem
                  title={localize('settings.addPaymentMethod.payIn')}
                  brandTextColor
                  leftItem={
                    <img
                      style={{
                        width: 31,
                        height: 20,
                        marginRight: 27,
                        marginLeft: -2,
                        objectFit: 'contain',
                      }}
                      src="/images/paymentPayIn.png"
                      alt="payin"
                    />
                  }
                  rightIcon="arrowRight"
                  leftIconSize={25}
                  rightIconSize={14}
                  onClick={onAddPayInPressed}
                />
              )}
            </>
          )}

          <div>
            <img
              className="add-sepa-logo"
              src="/images/trustLogos.png"
              alt="add-sepa-logo"
            />
          </div>
        </div>
        {isLocalizeAdded(localize, 'payment.select.faq') && (
          <div className="faq">
            <Markdown rehypePlugins={[rehypeRaw]}>
              {localize('payment.select.faq')}
            </Markdown>
          </div>
        )}
        <div className="screen-vertical-space" />
      </div>
    </div>
  );
};

export default SelectPaymentView;
