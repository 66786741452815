import React from 'react';
import BrandButton from '../../../../components/BrandButton/BrandButton';
import CarCheckPhotoWrapper from '../../../../components/CarCheckPhotoWrapper/CarCheckPhotoWrapper';
import HandOverCheckbox from './HandOverCheckbox';
import {
  LocalizePropType,
  SUPPORTED_END_RENTAL_VIOLATIONS,
} from 'getaway-data-layer';
import { GA_TITLES } from '../../../../../utils';

type Props = {
  localize: LocalizePropType;
  handoverChecklist: Array<object>;
  onLock: () => void;
  onSelectItem: any;
  fuelLevel: number;
  distanceToReturnLocation: string | number;
  onPressOption: (name: any) => void;
  finishRentalViolations: Array<string>;
  carCheckImages: Object;
  onPressRemoveImage: (index: number) => void;
  isServiceTrip: boolean;
  initialLoad: boolean;
  fuelLevelStart: number;
  fuelTankVolume: number;
};

const LockView = ({
  localize,
  handoverChecklist,
  onSelectItem,
  onLock,
  fuelLevel,
  fuelLevelStart,
  distanceToReturnLocation,
  onPressOption,
  finishRentalViolations,
  carCheckImages,
  onPressRemoveImage,
  isServiceTrip,
  initialLoad,
  fuelTankVolume,
}: Props) => {
  return (
    <div style={{ paddingBottom: 70 }}>
      {handoverChecklist.map((item: any) => {
        if (
          finishRentalViolations &&
          Object.keys(finishRentalViolations).includes(item.name)
        ) {
          return (
            <HandOverCheckbox
              key={item.name}
              onPress={onSelectItem}
              selected={item.isSelected}
              name={item.name}
              title={item.title}
              localize={localize}
              value={
                item.name === 'bitLessFuelOnReturn'
                  ? `${Math.ceil(
                      (fuelLevelStart - fuelLevel) * (fuelTankVolume / 100)
                    )}L`
                  : item.name === 'insideArea'
                  ? `~ ${distanceToReturnLocation}km`
                  : `${fuelLevelStart - fuelLevel}%`
              }
              icon={
                item.name === 'bitLessFuelOnReturn'
                  ? 'carFuel'
                  : item.name === 'insideArea'
                  ? 'returnArea'
                  : 'carFuel'
              }
              onPressOption={() => onPressOption(item.name)}
            />
          );
        }
        return null;
      })}
      {handoverChecklist.map((item: any) => {
        if (!SUPPORTED_END_RENTAL_VIOLATIONS.includes(item.name)) {
          return (item.name === 'key' || item.name === 'card') &&
            item.isSelected &&
            initialLoad ? null : (
            <HandOverCheckbox
              key={item.name}
              onPress={onSelectItem}
              selected={item.isSelected}
              name={item.name}
              title={localize(`handover.${item.name}.title`)}
              localize={localize}
            />
          );
        }
        return null;
      })}
      {!isServiceTrip && (
        <>
          <HandOverCheckbox
            localize={localize}
            selected={Object.keys(carCheckImages).length === 4}
            disabled
            name="carCheck"
            title={localize('handover.carCheck.title')}
            onPress={() => {}}
            containerStyles={{ borderBottomWidth: 0 }}
          />
          <div style={{ height: 4 }} />
          <CarCheckPhotoWrapper
            carCheckImages={carCheckImages}
            onPressRemoveImage={onPressRemoveImage}
          />
        </>
      )}
      <BrandButton
        id={GA_TITLES.RENTAL.FINISH}
        title={localize('rental.finish.button')}
        onClick={onLock}
        onClickDisabled={
          handoverChecklist.filter((item: any) => item.isSelected === false)
            .length > 0
            ? () => {}
            : onLock
        }
        disabled={
          handoverChecklist.filter((item: any) => item.isSelected === false)
            .length > 0 ||
          (Object.keys(carCheckImages).length !== 4 && !isServiceTrip)
        }
      />
    </div>
  );
};

export default LockView;
