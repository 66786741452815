import {
  APIResponse,
  UpdateUserInputData,
  UserDataOutput,
  Transactions,
  RentalHistoryDataList,
  PayByLinkData,
  PaymentRequestData,
  DeviceFingerprintInput,
  ChallengeSolutionInput,
} from '../../@types';
import { uploadFileFromStorage } from './clientHelpers/makeUploadFileAPICall';

export const userClient = {
  get: async (): Promise<APIResponse<UserDataOutput>> => {
    const { makeAPICall } = await import('./clientHelpers/makeAPICall');

    const { data, error } = await makeAPICall({
      method: 'GET',
      url: '/user',
      checkModified: false,
    });
    return {
      data,
      error,
    };
  },
  updateUser: async (
    updateUserInputData: UpdateUserInputData
  ): Promise<APIResponse<UserDataOutput>> => {
    const { makeAPICall } = await import('./clientHelpers/makeAPICall');

    const { notModified, data, error } = await makeAPICall({
      method: 'PUT',
      url: '/user',
      data: updateUserInputData,
    });
    return {
      notModified,
      data,
      error,
    };
  },
  generateMagicLink: async (
    sendEmail: boolean
  ): Promise<APIResponse<UserDataOutput>> => {
    const { makeAPICall } = await import('./clientHelpers/makeAPICall');

    const { notModified, data, error } = await makeAPICall({
      method: 'POST',
      url: `/user/generateMagicLink?sendEmail=${sendEmail}`,
    });
    return {
      notModified,
      data,
      error,
    };
  },
  verifyEmail: async (
    verificationCode: string
  ): Promise<APIResponse<UserDataOutput>> => {
    const { makeAPICall } = await import('./clientHelpers/makeAPICall');

    const { notModified, data, error } = await makeAPICall({
      method: 'PUT',
      url: `/user/verifyEmail?verificationCode=${verificationCode}`,
    });
    return {
      notModified,
      data,
      error,
    };
  },
  getGDPR: async (verificationCode: string): Promise<any> => {
    const { makeAPICall } = await import('./clientHelpers/makeAPICall');

    const { notModified, data, error, headers } = await makeAPICall({
      method: 'GET',
      url: `/user/gdpr?verificationCode=${verificationCode}`,
      headers: {
        Accept: 'application/zip',
      },
    });
    return {
      notModified,
      data,
      error,
      headers,
    };
  },
  reSendVerificationEmail: async (): Promise<APIResponse<UserDataOutput>> => {
    const { makeAPICall } = await import('./clientHelpers/makeAPICall');

    const { notModified, data, error } = await makeAPICall({
      method: 'PUT',
      url: `/user/reSendEmailVerificationEmail`,
    });
    return {
      notModified,
      data,
      error,
    };
  },
  getPaymentData: async (
    id: string
  ): Promise<APIResponse<PaymentRequestData>> => {
    const { makeAPICall } = await import('./clientHelpers/makeAPICall');

    const { notModified, data, error } = await makeAPICall({
      method: 'GET',
      url: `/user/paymentMethods/paymentRequest/${id}`,
    });
    return {
      notModified,
      data,
      error,
    };
  },
  addPayment: async (
    paymentMethodDataInput: any
  ): Promise<APIResponse<UserDataOutput>> => {
    const { makeAPICall } = await import('./clientHelpers/makeAPICall');

    const { notModified, data, error } = await makeAPICall({
      method: 'POST',
      url: '/user/paymentMethods',
      data: paymentMethodDataInput,
    });
    return {
      notModified,
      data,
      error,
    };
  },
  getPaymentMethods: async (): Promise<APIResponse<any>> => {
    const { makeAPICall } = await import('./clientHelpers/makeAPICall');

    const { notModified, data, error } = await makeAPICall({
      method: 'GET',
      url: 'user/paymentMethods/adyen/paymentMethods',
    });
    return {
      notModified,
      data,
      error,
    };
  },
  addPaymentDetails: async (paymentDetails: any): Promise<APIResponse<any>> => {
    const { makeAPICall } = await import('./clientHelpers/makeAPICall');

    const { notModified, data, error } = await makeAPICall({
      method: 'POST',
      url: 'user/paymentMethods/adyen/paymentDetails',
      data: paymentDetails,
    });
    return {
      notModified,
      data,
      error,
    };
  },
  updatePayment: async (
    paymentId: string,
    paymentData: any
  ): Promise<APIResponse<UserDataOutput>> => {
    const { makeAPICall } = await import('./clientHelpers/makeAPICall');

    const { data, error } = await makeAPICall({
      method: 'PUT',
      url: `/user/paymentMethods/${paymentId}`,
      data: paymentData,
    });
    return {
      data,
      error,
    };
  },
  deletePayment: async (
    paymentId: string
  ): Promise<APIResponse<UserDataOutput>> => {
    const { makeAPICall } = await import('./clientHelpers/makeAPICall');

    const { data, error } = await makeAPICall({
      method: 'DELETE',
      url: `/user/paymentMethods/${paymentId}`,
    });
    return {
      data,
      error,
    };
  },
  deletePhone: async (
    verificationCode: string
  ): Promise<APIResponse<UserDataOutput>> => {
    const { makeAPICall } = await import('./clientHelpers/makeAPICall');

    const { data, error } = await makeAPICall({
      method: 'DELETE',
      url: `/user/phoneNumber?verificationCode=${verificationCode}`,
    });
    return {
      data,
      error,
    };
  },
  getTransactions: async (
    offset: number = 0,
    limit: number = 20
  ): Promise<APIResponse<Transactions>> => {
    const { makeAPICall } = await import('./clientHelpers/makeAPICall');

    const { notModified, data, error } = await makeAPICall({
      method: 'GET',
      url: '/user/transactions',
      params: {
        offset,
        limit,
      },
    });
    return {
      notModified,
      data,
      error,
    };
  },
  getTransactionPaymentLink: async (
    transactionId: number
  ): Promise<APIResponse<PayByLinkData>> => {
    const { makeAPICall } = await import('./clientHelpers/makeAPICall');

    const { notModified, data, error } = await makeAPICall({
      method: 'GET',
      url: `/user/transactions/${transactionId}/paymentLink`,
    });
    return {
      notModified,
      data,
      error,
    };
  },
  terminateUser: async (): Promise<APIResponse<null>> => {
    const { makeAPICall } = await import('./clientHelpers/makeAPICall');

    const { error } = await makeAPICall({
      method: 'DELETE',
      url: '/user',
    });
    return {
      error,
    };
  },
  jumioDocumentUploaded: async (
    scanReference: string,
    type: string
  ): Promise<APIResponse<any>> => {
    const { makeAPICall } = await import('./clientHelpers/makeAPICall');

    const { notModified, error } = await makeAPICall({
      method: 'PUT',
      url: `/user/jumioImageUploaded/${scanReference}`,
      params: {
        documentType: type,
      },
    });
    return {
      notModified,
      error,
    };
  },
  schufaCheck: async (
    updateUserInputData: UpdateUserInputData
  ): Promise<APIResponse<UserDataOutput>> => {
    const { makeAPICall } = await import('./clientHelpers/makeAPICall');

    const { notModified, data, error } = await makeAPICall({
      method: 'PUT',
      url: '/user/schufaCheck',
      data: updateUserInputData,
    });
    return {
      notModified,
      data,
      error,
    };
  },
  addressCheck: async (
    updateUserInputData: UpdateUserInputData
  ): Promise<APIResponse<UserDataOutput>> => {
    const { makeAPICall } = await import('./clientHelpers/makeAPICall');

    const { notModified, data, error } = await makeAPICall({
      method: 'PUT',
      url: '/user/addressCheck',
      data: updateUserInputData,
    });
    return {
      notModified,
      data,
      error,
    };
  },
  sendVerificationCode: async (): Promise<APIResponse<null>> => {
    const { makeAPICall } = await import('./clientHelpers/makeAPICall');

    const { error } = await makeAPICall({
      method: 'GET',
      url: '/user/sendVerificationCode',
    });
    return {
      error,
    };
  },
  joinCircle: async (
    circleCode: string
  ): Promise<APIResponse<UserDataOutput>> => {
    const { makeAPICall } = await import('./clientHelpers/makeAPICall');

    const { data, error } = await makeAPICall({
      method: 'PUT',
      url: `/user/circle/${circleCode}`,
    });
    return {
      data,
      error,
    };
  },
  removeCircle: async (
    circleCode: string
  ): Promise<APIResponse<UserDataOutput>> => {
    const { makeAPICall } = await import('./clientHelpers/makeAPICall');

    const { data, error } = await makeAPICall({
      method: 'DELETE',
      url: `/user/circle/${circleCode}`,
    });
    return {
      data,
      error,
    };
  },
  getRentalHistory: async (
    afterId: string | null | undefined = null,
    limit: number = 20
  ): Promise<APIResponse<RentalHistoryDataList>> => {
    const { makeAPICall } = await import('./clientHelpers/makeAPICall');

    const { notModified, data, error } = await makeAPICall({
      method: 'GET',
      url: '/user/rentals',
      params: {
        afterId,
        limit,
      },
    });
    return {
      notModified,
      data,
      error,
    };
  },
  uploadDocument: async (
    uri: string,
    type: 'ADDRESS_PROOF' | 'OTHER'
  ): Promise<APIResponse<null>> => {
    const { error } = await uploadFileFromStorage(
      `user/uploadDocument?documentType=${type}`,
      uri,
      'PUT'
    );
    return {
      error,
    };
  },
  webUploadDocument: async (
    base64: any,
    type: 'ADDRESS_PROOF' | 'OTHER'
  ): Promise<APIResponse<null>> => {
    const binaryData = atob(base64.split(',')[1]);
    const uint8Array = new Uint8Array(binaryData.length);

    for (let i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i);
    }
    const { makeAPICall } = await import('./clientHelpers/makeAPICall');

    const { error } = await makeAPICall({
      method: 'PUT',
      url: `user/uploadDocument?documentType=${type}`,
      data: uint8Array.buffer,
      headers: {
        'Content-Type': 'application/octet-stream',
      },
    });
    return {
      error,
    };
  },
  getPaymentPublicKey: async (): Promise<APIResponse<string>> => {
    const { makeAPICall } = await import('./clientHelpers/makeAPICall');

    const { data, error } = await makeAPICall({
      method: 'GET',
      url: '/user/paymentMethods/adyen/publicKey',
      headers: {
        Accept: '*/*',
      },
    });
    return {
      data,
      error,
    };
  },
  initJumioForMobile: async (
    jumioMobileInput: any
  ): Promise<APIResponse<any>> => {
    const { makeAPICall } = await import('./clientHelpers/makeAPICall');

    const { notModified, data, error } = await makeAPICall({
      method: 'POST',
      url: '/user/jumio/verification',
      data: jumioMobileInput,
    });
    return {
      notModified,
      data,
      error,
    };
  },
  initJumioForWeb: async (jumioWebInput: any): Promise<APIResponse<any>> => {
    const { makeAPICall } = await import('./clientHelpers/makeAPICall');

    const { notModified, data, error } = await makeAPICall({
      method: 'POST',
      url: '/user/jumio/initiateForWeb',
      data: jumioWebInput,
    });
    return {
      notModified,
      data,
      error,
    };
  },
  verifyJumioSuccess: async (scanRef: any): Promise<APIResponse<any>> => {
    const { makeAPICall } = await import('./clientHelpers/makeAPICall');

    const { notModified, data, error } = await makeAPICall({
      method: 'POST',
      url: `user/verify/user/success/${scanRef}`,
    });
    return {
      notModified,
      data,
      error,
    };
  },
  generateDepositPaymentLink: async (
    amount: any,
    returnUrl: string
  ): Promise<APIResponse<any>> => {
    const { makeAPICall } = await import('./clientHelpers/makeAPICall');

    const { notModified, data, error } = await makeAPICall({
      method: 'POST',
      url: `user/deposit/paymentLink`,
      data: { amount, returnUrl },
    });
    return {
      notModified,
      data,
      error,
    };
  },
  refundDeposit: async (): Promise<APIResponse<any>> => {
    const { makeAPICall } = await import('./clientHelpers/makeAPICall');

    const { data, notModified, error } = await makeAPICall({
      method: 'POST',
      url: `/user/deposit/refund`,
    });
    return {
      data,
      notModified,
      error,
    };
  },
  loadTariffs: async (): Promise<APIResponse<any>> => {
    const { makeAPICall } = await import('./clientHelpers/makeAPICall');

    const { data, notModified, error } = await makeAPICall({
      method: 'GET',
      url: `/user/availableTariffs`,
    });
    return {
      data,
      notModified,
      error,
    };
  },
  submitIdentityShopper: async (
    deviceFingerprintInput: DeviceFingerprintInput
  ): Promise<APIResponse<UserDataOutput>> => {
    const { makeAPICall } = await import('./clientHelpers/makeAPICall');
    const { data, error } = await makeAPICall({
      method: 'POST',
      url: '/payment/adyen/3ds/deviceFingerprint',
      checkModified: false,
      data: deviceFingerprintInput,
    });
    return {
      data,
      error,
    };
  },
  submitChallenge: async (
    challengeSolutionInput: ChallengeSolutionInput
  ): Promise<APIResponse<UserDataOutput>> => {
    const { makeAPICall } = await import('./clientHelpers/makeAPICall');
    const { data, error } = await makeAPICall({
      method: 'POST',
      url: '/payment/adyen/3ds/challengeSolution',
      checkModified: false,
      data: challengeSolutionInput,
    });
    return {
      data,
      error,
    };
  },
  isPaymentVerified: async (): Promise<APIResponse<UserDataOutput>> => {
    const { makeAPICall } = await import('./clientHelpers/makeAPICall');
    const { data, error } = await makeAPICall({
      method: 'PUT',
      url: '/payment/adyen/3ds/scaCheck',
      checkModified: false,
    });
    return {
      data,
      error,
    };
  },
};
